import React, {
  FC,
  Key,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table, Popconfirm, Select, Button } from 'antd';
import EditableRow from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/EditableRow';
import EditableCell from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/EditableCell';
import { ProductEntry } from '@apps/warehouse/services/namespace';
import { ColumnType } from 'antd/lib/table';
import { ProductEntriesContainer } from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/styles.sc';
import getProductsForEcosystem from '@apps/products/services/getProductsForEcosystem';
import { Product } from '@apps/products/services';
import { useIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';

type ProductEntriesProps = {
  entries: ProductEntry[];
  setEntries(entry: ProductEntry[]): void;
  count: number;
  setCount(count: number): void;
  selectedEcosystem: string | null;
};

const ProductEntries: FC<ProductEntriesProps> = ({
  entries,
  setEntries,
  count,
  setCount,
  selectedEcosystem,
}) => {
  const intl = useIntl();
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleDelete = useCallback(
    (key: Key) => {
      const newData = entries.filter((item) => item.key !== key);
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const handleSave = useCallback(
    (row: ProductEntry) => {
      const newData = [...entries];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const defaultColumns: (ColumnType<any> & {
    editable?: boolean;
    dataIndex?: string;
    type?: 'dropdown' | 'number' | 'string';
    selectedEcosystem?: ProductEntriesProps['selectedEcosystem'];
  })[] = [
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.productnumber',
        defaultMessage: 'Product number',
      }),
      dataIndex: 'productNumber',
      type: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.productname',
        defaultMessage: 'Product name',
      }),
      dataIndex: 'productName',
      type: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      editable: true,
      type: 'number',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.unit',
        defaultMessage: 'Unit',
      }),
      dataIndex: 'unit',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.storage',
        defaultMessage: 'Storage',
      }),
      dataIndex: 'storageId',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      render: (_, record: ProductEntry) =>
        entries.length >= 1 ? (
          <Popconfirm
            title={intl.formatMessage({
              id: 'warehouse.create.product.remove.confirm',
              defaultMessage: 'Sure to delete?',
            })}
            onConfirm={() => handleDelete(record.key)}
          >
            <Button icon={<CloseOutlined />} size="small" shape="circle" />
          </Popconfirm>
        ) : null,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProductEntry) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        selectedEcosystem: col.selectedEcosystem,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    if (selectedEcosystem) {
      setProducts([]);
      getProductsForEcosystem(selectedEcosystem)
        ?.then(setProducts)
        ?.catch((e) => {
          console.warn(e);
        });
    }
  }, [selectedEcosystem]);

  const prodOptions = useMemo(() => {
    return products.map((prod, index) => {
      return (
        <Select.Option key={index} value={prod.id}>
          {prod.productName} {prod.productNumber}
        </Select.Option>
      );
    });
  }, [products]);

  const handleSelect = useCallback(
    (id) => {
      const selectedProduct = products.find((prod) => prod.id === id);
      if (selectedProduct) {
        const newData: ProductEntry = {
          key: count,
          amount: 0,
          productId: selectedProduct.id,
          productName: selectedProduct.productName,
          productNumber: selectedProduct.productNumber,
          unit: selectedProduct.unit,
        };
        setEntries([...entries, newData]);
        setCount(count + 1);
      }
      setSelectedId(null);
    },
    [count, entries, products, setCount, setEntries],
  );

  return (
    <ProductEntriesContainer>
      {selectedEcosystem && (
        <Select
          className="animate__animated animate__slideInLeft animate__faster"
          onSelect={handleSelect}
          style={{ marginBottom: 16, minWidth: '200px' }}
          disabled={!selectedEcosystem}
          value={selectedId}
          placeholder={intl.formatMessage({
            id: 'warehouse.create.select.product.placeholder',
            defaultMessage: 'Select product...',
          })}
        >
          {prodOptions}
        </Select>
      )}

      <Table
        components={components}
        rowClassName={() =>
          'editable-row animate__animated animate__slideInUp animate__faster'
        }
        bordered
        dataSource={entries}
        columns={columns as any}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
        }}
      />
    </ProductEntriesContainer>
  );
};

export default ProductEntries;
