import React, { useEffect, useState } from 'react';
import { fetchUser } from '@services/users';
import SpinContainer from '@components/SpinContainer';
import { Avatar, Tooltip } from 'antd';

export type UserRendererProps = {
  value: string | string[];
};

const UserRenderer: React.FC<UserRendererProps> = ({ value }) => {
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!value) {
      return;
    }
    setIsLoading(true);
    if (Array.isArray(value)) {
      const promises = value.map((id) => fetchUser(id));
      Promise.all(promises)
        .then((fetchedUsers) => {
          setUsers(fetchedUsers);
        })
        .finally(() => setIsLoading(false));
    } else {
      fetchUser(value)
        .then((user: User) => {
          setUser(user);
        })
        .finally(() => setIsLoading(false));
    }
  }, [value]);

  return (
    <div>
      {isLoading && <SpinContainer />}
      {!isLoading && user && (
        <Tooltip placement="bottom" title={user?.displayName}>
          <Avatar src={user?.photoURL} size="small" />
        </Tooltip>
      )}
      {!isLoading && users.length > 0 && (
        <Avatar.Group>
          {users.map((user, index) => (
            <Tooltip key={index} placement="bottom" title={user?.displayName}>
              <Avatar src={user?.photoURL} size="small" />
            </Tooltip>
          ))}
        </Avatar.Group>
      )}
    </div>
  );
};

export default UserRenderer;
