import React, { FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { WeekPlanEntry, getWeekPlans } from '@apps/weekplan/services';
import { StyledDashboardCard } from '@pages/dashboard';
import WeekplanAppConfig from '@apps/weekplan';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [weekplans, setWeekplans] = useState<WeekPlanEntry[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getWeekPlans({
        fields: ['id', 'ecosystem', 'name', 'lanes'],
        limit: 3,
      })
        ?.then((response) => {
          setWeekplans(response.results as WeekPlanEntry[]);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setWeekplans]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {weekplans.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="horizontal">
          {weekplans.map((weekplan, index) => (
            <WidgetCard
              key={index}
              title={
                <div>
                  <EcosystemAvatar id={weekplan.ecosystem} /> {weekplan.name}
                </div>
              }
              bordered={false}
              extra={
                <Link to={`app/${WeekplanAppConfig.todixId}`}>
                  {intl.formatMessage({
                    id: 'app.weekplan.dashboard.link',
                    defaultMessage: 'Go to weekplans',
                  })}
                </Link>
              }
            >
              {''}
            </WidgetCard>
          ))}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`app/${WeekplanAppConfig.todixId}/create`}>
            <HugeButton
              type="dashed"
              icon={<WeekplanAppConfig.mainMenu.icon />}
            >
              <FormattedMessage
                id="app.weekplan.dashboard.empty.link"
                defaultMessage="Create your first Weekplan now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
