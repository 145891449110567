import React, { FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { Project, getProjects } from '@apps/projects/services';
import { StyledDashboardCard } from '@pages/dashboard';
import ProjectsAppConfig from '@apps/projects';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getProjects({
        fields: ['id', 'ecosystem', 'name', 'managerId', 'membersIds'],
        limit: 3,
      })
        ?.then((response) => {
          setProjects(response.results as Project[]);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setProjects]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {projects.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="horizontal">
          {projects.map((project, index) => (
            <WidgetCard
              key={index}
              title={
                <div>
                  <EcosystemAvatar id={project.ecosystem} /> {project.name}
                </div>
              }
              bordered={false}
              extra={
                <Link to={`app/${ProjectsAppConfig.todixId}`}>
                  {intl.formatMessage({
                    id: 'app.projects.dashboard.link',
                    defaultMessage: 'Go to projects',
                  })}
                </Link>
              }
            >
              {''}
            </WidgetCard>
          ))}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`app/${ProjectsAppConfig.todixId}/create`}>
            <HugeButton
              type="dashed"
              icon={<ProjectsAppConfig.mainMenu.icon />}
            >
              <FormattedMessage
                id="app.projects.dashboard.empty.link"
                defaultMessage="Create your first Project now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
