import moment from 'moment';

export const PROJECTS_COLLECTION_NAME = 'projects';

type Entity = {
  id: string;
  refCollection: string;
};

export type EntityWithType = Entity & {
  type: 'project' | 'task';
};

export type ProjectForm = {
  id?: string;
  isFav?: boolean;
  ecosystem: string;
  name: string;
  color: string;
  creator: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  managerId: string;
  membersIds: string[];
  timestamp?: number;
};

export type Project = {
  isFav?: boolean;
  id?: string;
  ecosystem: string;
  name: string;
  color: string;
  creator: string;
  startDate: string;
  endDate: string;
  managerId: string;
  membersIds: string[];
  timestamp?: number;
};

export type GetParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

export type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export type GetApiResponse = (
  params: GetParams,
) => Promise<ApiResponse> | undefined;
