import { firebase } from '@services/Firebase';
import { patch, post } from '@services/api';

const COLLECTION_NAME = 'appRoles';

export const createAppRole = (appRole: AppRole): Promise<string> => {
  return post(COLLECTION_NAME, { ...appRole });
};

export const updateAppRole = (appRole: AppRole): Promise<void> => {
  return patch(`${COLLECTION_NAME}/${appRole.id}`, appRole);
};

export const getAppRolesByEcosystem = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as AppRole)),
    );
};
