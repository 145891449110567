import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IGetRowsParams } from 'ag-grid-community';

import { deleteTask, getTasks, patchTask } from '@apps/tasks/services';
import AppView from '@components/AppView';
import { columns } from './components/columns';
import { SideContent } from './components/SideContent';
import Modal from '@components/Modal';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import { FilterTextObject } from '@services/filtering';

export const View: FC<{}> = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getTasks({
      query: params.query,
      offset: params.startRow,
      limit: params.endRow - params.startRow,
      fields: [
        'id',
        'ecosystem',
        'creationDate',
        'createdBy',
        'title',
        'description',
        'dueDate',
        'status',
        'assignee',
        'progress',
        'assignees',
        'estimation',
        'followers',
        'creator',
        'startDate',
        'doneDate',
        'documents',
        'parents',
        'children',
      ],
      filters: Object.fromEntries(filtersEntries),
      sort: params.sortModel.map(
        (model: { colId: string; sort: string }) =>
          `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
      ),
    }).then((response) => {
      // ts ignored because I want if element exists there for old, incompatible data filtering
      // @ts-ignore
      const filteredResults = response.results.filter((el) => el.creator);
      params.successCallback(filteredResults, filteredResults.length);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.tasks',
            defaultMessage: 'Tasks',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={(id) =>
        deleteTask(id)
          ?.then(() => {
            // message.success(
            //   intl.formatMessage({
            //     id: 'tasks.deletetask.success',
            //     defaultMessage: 'Task deleted',
            //   }),
            // );
          })
          .catch(() => {
            message.error(
              intl.formatMessage({
                id: 'tasks.deletetask.error',
                defaultMessage: 'Error while deleting task',
              }),
            );
          })
      }
      getExtraOptions={(item) => {
        const activationLabel = item.isActive ? 'deactivate' : 'reactivate';
        return [
          {
            children: activationLabel,
            onClick: () => {
              Modal.confirm({
                content: intl.formatMessage(
                  {
                    id: 'tasks.confirmation',
                    defaultMessage:
                      'Are you sure you want to {action} this item?',
                  },
                  { action: activationLabel },
                ),
                onOk: () => {
                  patchTask(item.id, {})
                    .then(() => {
                      // message.success(
                      //   intl.formatMessage({
                      //     id: 'tasks.patchtask.success',
                      //     defaultMessage: 'Task updated',
                      //   }),
                      // );
                    })
                    .catch(() => {
                      message.error(
                        intl.formatMessage({
                          id: 'tasks.patchtask.error',
                          defaultMessage: 'Error while updating task',
                        }),
                      );
                    });
                },
              });
            },
          },
        ];
      }}
    />
  );
};
