import React, { FC, useState, useCallback, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import AppConfig from '@apps/warehouse';
import Plot from '@apps/warehouse/mainMenu/components/Plot';
import HistoryTable from '@apps/warehouse/mainMenu/components/HistoryTable';
import {
  WarehouseStorage,
  WarehouseHistoryEntry,
  HistoryTotalEntry,
} from '@apps/warehouse/services/namespace';
import fetchHistoryForStorage from '@apps/warehouse/services/fetchHistoryForStorage';
import fetchTotalStatsHistoryForStorage from '@apps/warehouse/services/fetchTotalStatsHistoryForStorage';
import Controls from '@apps/warehouse/mainMenu/components/Controls';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

const MainView: FC = () => {
  const [form] = useForm();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(AppConfig.todixId);
  useEffect(() => {
    if (firstActiveEcosystemId) {
      setSelectedEcosystem(firstActiveEcosystemId);
      form.setFieldsValue({
        storage: null,
        ecosystem: firstActiveEcosystemId,
      });
    }
  }, [firstActiveEcosystemId, form]);
  const [historyEntries, setHistoryEntries] = useState<WarehouseHistoryEntry[]>(
    [],
  );
  const [totalStatsHistoryEntries, setTotalStatsHistoryEntries] = useState<
    HistoryTotalEntry[]
  >([]);
  const [selectedEcosystem, setSelectedEcosystem] = useState<string | null>(
    firstActiveEcosystemId || null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleStorageSelect = useCallback(
    (storage: WarehouseStorage) => {
      setIsLoading(true);
      const fetchingHistory = fetchHistoryForStorage({
        ecosystem: selectedEcosystem as string,
        storageId: storage.id as string,
      })?.then((entries) => setHistoryEntries(entries));
      const fetchingTotalStatsHistory = fetchTotalStatsHistoryForStorage({
        ecosystem: selectedEcosystem as string,
        storageId: storage.id as string,
      })?.then((entries) => setTotalStatsHistoryEntries(entries));
      Promise.all([fetchingHistory, fetchingTotalStatsHistory]).finally(() => {
        setIsLoading(false);
      });
    },
    [selectedEcosystem],
  );
  const handleEcosystemSelect = useCallback(
    (ecosystem: string) => {
      form.setFieldsValue({
        storage: null,
      });
      setSelectedEcosystem(ecosystem);
      setHistoryEntries([]);
      setTotalStatsHistoryEntries([]);
    },
    [form],
  );
  return (
    <div>
      <Controls
        onEcosystemSelect={handleEcosystemSelect}
        onStorageSelect={handleStorageSelect}
        selectedEcosystem={selectedEcosystem}
        isLoading={isLoading}
        ecosystemId={firstActiveEcosystemId}
      />
      <Plot data={totalStatsHistoryEntries} />
      <HistoryTable data={historyEntries} />
    </div>
  );
};

export default MainView;
