import React, { FC, useCallback, useState } from 'react';
import { useCustomEventListener } from '@core/services';
import Stacked, { OwnStackedProps } from '@components/AppView/Stacked';
import { ModalFuncProps } from 'antd/lib/modal';
import Modal from '@components/Modal';
import { useIntl } from 'react-intl';
import { StackedSortType } from '.';

type StackedWithListenerProps = Omit<OwnStackedProps, 'handleNavigation'> & {
  onResourceChangeEventName?: string;
  onResourceSaveEventName?: string;
  setStackedSort: (arg: StackedSortType) => void;
  stackedSort: StackedSortType;
  showNavigation: boolean;
};
const StackedWithListener: FC<StackedWithListenerProps> = ({
  onResourceChangeEventName,
  onResourceSaveEventName,
  ...rest
}: StackedWithListenerProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const intl = useIntl();

  useCustomEventListener(onResourceChangeEventName || '', () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  });

  useCustomEventListener(onResourceSaveEventName || '', (resource: any) => {
    if (resource && !resource.isDraft) {
      rest.setSelectedRow(null);
    }
    setIsDirty(false);
  });

  const handleNavigation = useCallback(
    (
      onDiscard?: ModalFuncProps['onOk'],
      onCancel?: ModalFuncProps['onCancel'],
    ) => {
      if (isDirty) {
        Modal.confirm({
          content: intl.formatMessage({
            id: 'aappview.stacked.discard.confirm',
            defaultMessage: 'Discard changes?',
          }),
          onOk: () => {
            if (onDiscard) {
              onDiscard();
              setIsDirty(false);
            }
          },
          onCancel,
        });
      } else if (onDiscard) {
        onDiscard();
      }
    },
    [intl, isDirty],
  );

  return <Stacked {...rest} handleNavigation={handleNavigation} />;
};

export default StackedWithListener;
