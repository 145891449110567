import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@components/Tabs';
import { Card } from '@todix/ui-components';
import { DashboardOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import BaseTab from '@apps/documents/mainMenu/Details/BaseTab';
import { FormProps } from '@apps/documents/mainMenu/Details/Form';
import { deleteDocument, PaymentEntity } from '@apps/documents/service';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';
import { message } from 'antd';
import moment from 'moment';
import optionsMapper from '@core/helpers/optionsMapper';
import { mapObjectsWithDateStringsToMoments } from '@core/helpers/momentToFormatString';
import Relations from '@components/Relations';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import AppConfig from '@apps/documents';
import { nameValueGetter } from '@apps/documents/mainMenu/Archive/valueGetters';
import Download from '@components/Download';
import DocumentInfoPopup from '@apps/documents/mainMenu/Inbox/DocumentInfoPopup';

const { TabPane } = Tabs;

type DetailedDocumentProps = Partial<
  FormProps & {
    id: string;
  }
>;

const DetailedDocument: React.FC<DetailedDocumentProps> = ({
  id,
  initialValues = {},
  onSubmit = () => {},
  readOnly,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const entries = initialValues
    ? Object.entries(initialValues).map((entry) => {
        if (entry[0] === 'payments') {
          return [
            entry[0],
            (entry[1] as PaymentEntity[]).map((payment) => ({
              ...payment,
              payDate: moment(payment.payDate as string, moment.defaultFormat),
            })),
          ];
        }
        return entry;
      })
    : [];
  let parsedValues = Object.fromEntries(entries);

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}/archive`,
        intl.formatMessage({
          id: 'detaileddocument.nav.documents',
          defaultMessage: 'Documents',
        }),
      ],
      id
        ? [
            `/app/${AppConfig.todixId}/${id}`,
            nameValueGetter(initialValues, intl),
          ]
        : [
            `/app/${AppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'documents.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [id, initialValues, intl]);

  parsedValues = mapObjectsWithDateStringsToMoments(parsedValues);

  return (
    <>
      <Card style={{ height: '100%' }}>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {}}
          tabBarExtraContent={
            id && (
              <ActionRenderer
                path={`app/${AppConfig.todixId}/${id}`}
                options={optionsMapper(['delete'], intl)}
                extraOptions={[
                  {
                    children: (
                      <Download
                        url={parsedValues?.documentUrl}
                        filename={`${parsedValues?.id}.${parsedValues?.fileDetails?.format}`}
                      >
                        <FormattedMessage
                          id="documents.inbox.sidecontent.download"
                          defaultMessage="download"
                        />
                      </Download>
                    ),
                  },
                  {
                    children: (
                      <DocumentInfoPopup document={parsedValues}>
                        <a href="">
                          <FormattedMessage
                            id="documents.inbox.sidecontent.upload.details"
                            defaultMessage="upload details"
                          />
                        </a>
                      </DocumentInfoPopup>
                    ),
                  },
                ]}
                handleDelete={() => {
                  deleteDocument(id)
                    ?.then(() => {
                      // message.success(
                      //   intl.formatMessage({
                      //     id: 'documents.deletedocument.success',
                      //     defaultMessage: 'Document deleted',
                      //   }),
                      // );
                      history.push(`/app/${AppConfig.todixId}`);
                    })
                    .catch(() => {
                      message.error(
                        intl.formatMessage({
                          id: 'products.deletedocument.error',
                          defaultMessage: 'Error while deleting document',
                        }),
                      );
                    });
                }}
                intl={intl}
              />
            )
          }
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="detaileddocument.tab.base"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <BaseTab
              id={id}
              initialValues={parsedValues}
              onSubmit={onSubmit}
              readOnly={readOnly}
            />
          </TabPane>
          <TabPane
            key="relations"
            disabled={!id || !initialValues?.ecosystem}
            tab={
              <>
                <NodeIndexOutlined />{' '}
                <FormattedMessage
                  id="relations.tab"
                  defaultMessage="Relations"
                />
              </>
            }
          >
            {initialValues?.ecosystem && id && (
              <Relations
                ecosystem={initialValues.ecosystem}
                entityId={id}
                entityType="document"
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default DetailedDocument;
