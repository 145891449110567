import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Form, Avatar } from 'antd';

import { RootStore } from '@core/store';
import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import {
  getActiveEcosystems,
  getActiveEcosystemsForApp,
} from '@components/EcosystemIndicator/store';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = (state: RootStore, props: any) => ({
  ecosystems: props.appId
    ? getActiveEcosystemsForApp(state, props.appId)
    : getActiveEcosystems(state),
});

export type StateProps = ReturnType<typeof mapStateToProps> & {
  onChange?: any;
  disabled?: boolean;
};

export const SelectedEcosystem: FC<StateProps> = ({
  ecosystems,
  onChange,
  disabled,
}) => {
  const { readOnly } = useFormContext();
  return (
    <Item
      name="ecosystem"
      label={
        <FormattedMessage
          id="tasks.selectedecosystem.label"
          defaultMessage="Ecosystem"
        />
      }
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="tasks.selectedecosystem.error"
              defaultMessage="Missing ecosystem"
            />
          ),
        },
      ]}
    >
      <Select
        name="ecosystem"
        placeholder={
          <FormattedMessage
            id="tasks.selectedecosystem.placeholder"
            defaultMessage="Ecosystem"
          />
        }
        aria-required="true"
        onChange={onChange}
        disabled={readOnly || disabled}
      >
        {ecosystems.map(({ id, name, avatarUrl }) => (
          <Option key={id} value={id}>
            <Avatar src={avatarUrl} size="small" /> {name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default connect(mapStateToProps)(SelectedEcosystem);
