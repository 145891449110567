import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DetailedContact from '@apps/contacts/mainMenu/components/DetailedContact';
import { getContact } from '@apps/contacts/services';
import { Spin } from 'antd';

type Props = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
};

const Preview: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [details, setDetails] = useState<any>(null);
  useEffect(() => {
    getContact(id)?.then(setDetails);
  }, [id]);

  return (
    <Spin spinning={false}>
      <DetailedContact readOnly id={id} initialValues={details} />
    </Spin>
  );
};

export default Preview;
