import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import {
  ItemNoMargin,
  ShortInput,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
const { Item } = Form;

type Props = InputProps & {
  name: string;
};

const calculateValues = (value: string, formValues: Record<string, any>) => {
  const { discountedPrice, amount } = formValues;
  const taxValue = value !== 'undefined' && value !== '' ? Number(value) : 0;
  const grossPrice = Number(
    Number(discountedPrice + discountedPrice * (taxValue / 100)).toFixed(2),
  );
  const totalGrossPrice = amount * grossPrice;
  return {
    taxValue,
    grossPrice,
    totalGrossPrice,
  };
};

const TaxSelection: FC<Props> = ({ name, ...inputProps }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <ShortInput
              {...inputProps}
              maxLength={4}
              onChange={(event) => {
                const value = event.target.value;
                const newValues = calculateValues(value, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default TaxSelection;
