import { database } from 'firebase';
import { rootStore } from '@core/store';
import { DeleteLicenceAction, UpdateLicenceAction } from '@core/store/licences';
import { LICENCES } from '@services/licences/namespace';
import { getEcosystemRelationsByMemberId } from '@services/ecosystems';

const addLicence = (data: database.DataSnapshot, userId: string) => {
  const licence = data.val() as Licence;
  if (licence.ownerId === userId) {
    rootStore.dispatch({
      type: 'UPDATE_LICENCE',
      payload: {
        [data.key as string]: licence,
      },
    } as UpdateLicenceAction);
  }
};

const updateLicence = (data: database.DataSnapshot, userId: string) => {
  const licence = data.val() as Licence;
  if (licence.ownerId === userId) {
    rootStore.dispatch({
      type: 'UPDATE_LICENCE',
      payload: {
        [data.key as string]: licence,
      },
    } as UpdateLicenceAction);
  }
};

const deleteLicence = (data: database.DataSnapshot, userId: string) => {
  const licence = data.val() as Licence;
  if (licence.ownerId === userId) {
    rootStore.dispatch({
      type: 'DELETE_LICENCE',
      payload: {
        [data.key as string]: licence,
      },
    } as DeleteLicenceAction);
  }
};

const licencesListener = async (userId: string, db: database.Database) => {
  const relations = await getEcosystemRelationsByMemberId(userId);

  if (!relations) {
    return;
  }

  const ecosystemIds = relations.map((relation) => relation.ecosystem);

  ecosystemIds.forEach((id) => {
    const userLicencesRef = db.ref(`${LICENCES}/${id}`);
    userLicencesRef.on('child_added', (data) => {
      addLicence(data, userId);
    });

    userLicencesRef.on('child_changed', (data) => {
      updateLicence(data, userId);
    });

    userLicencesRef.on('child_removed', (data) => {
      deleteLicence(data, userId);
    });
  });
};

export default licencesListener;
