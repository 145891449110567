import React from 'react';
import { IGetRowsParams, IFilterDef } from 'ag-grid-community';
import { FormattedMessage, IntlShape } from 'react-intl';
import { getDocumentsArchived, Document } from '@apps/documents/service';
import AppView from '@core/components/AppView';
import SideContent from '@apps/documents/mainMenu/widgets/ArchivedDocumentsTab/SideContent';
import { layoutsMap } from '@apps/documents/service/layouts';
import { LayoutField } from '@apps/documents/service/namespace';
import columns from '@apps/documents/mainMenu/widgets/ArchivedDocumentsTab/columns';
import { FilterTextObject } from '@services/filtering';
import optionsMapper from '@core/helpers/optionsMapper';
import DocumentAppConfig from '@apps/documents';
import Tabs from '@components/Tabs';
import { isAppActivatedInEcosystem } from '@apps/utils';

const { TabPane } = Tabs;

type GetArchivedDocumentsTabTypePayload = {
  intl: any;
  fullEcosystem: Ecosystem;
  relatedDocuments?: Document[];
};

type GetArchivedDocumentsTabType = (
  payload: GetArchivedDocumentsTabTypePayload,
) => any;

type GetRowsParams = IGetRowsParams & {
  ecosystems?: string[];
  query?: string;
  fields?: string[];
};

const selectedFields = [
  'id',
  'ecosystem',
  'uploadDate',
  'creationDate',
  'creatingUser',
  'uploadingUser',
  'notes',
  'documentUrl',
  'fileDetails',
];

const getRows = (
  params: GetRowsParams,
  intl?: IntlShape,
  relatedDocuments?: Document[],
) => {
  if (relatedDocuments) {
    const response = {
      results: relatedDocuments,
      info: {
        results: relatedDocuments.length,
      },
    };
    params.successCallback(response.results, response.info.results);
    return;
  }
  const { fields = selectedFields } = params;

  const filtersEntries = Object.entries(params.filterModel).map(
    ([key, filterObj]) => [key, filterObj as IFilterDef],
  );
  if (!params.filterModel.ecosystem && params.ecosystems) {
    filtersEntries.push([
      'ecosystem',
      {
        filter: params.ecosystems.join('|'),
        filterType: 'text',
        type: 'contains',
      } as FilterTextObject,
    ]);
  }
  getDocumentsArchived(
    {
      query: params.query,
      offset: params.startRow,
      limit: params.endRow - params.startRow,
      fields,
      filters: Object.fromEntries(filtersEntries),
      sort: params.sortModel.map(
        (model: { colId: string; sort: string }) =>
          `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
      ),
    },
    intl,
  ).then((response) => {
    params.successCallback(response.results, response.info.results);
  });
};

const getGetRows =
  (fields: string[], intl: IntlShape, relatedDocuments?: Document[]) =>
  (params: GetRowsParams) =>
    getRows({ fields, ...params }, intl, relatedDocuments);

const getArchivedDocumentsTab: GetArchivedDocumentsTabType = ({
  intl,
  fullEcosystem,
  relatedDocuments,
}) => {
  const shouldRender = isAppActivatedInEcosystem(
    fullEcosystem,
    DocumentAppConfig.todixId,
  );

  if (!shouldRender) {
    return null;
  }

  const layouts = { ...layoutsMap };
  const entries = Object.entries(layouts).map(([key, value]) => [
    key,
    value('sideView'),
  ]);

  const layoutsDetails = Object.fromEntries(entries);
  // @ts-ignore
  const fields = Object.values(layoutsDetails)
    // @ts-ignore
    .map((fields: any) =>
      fields.map((field: LayoutField) => ({
        fieldName: field.fieldName,
        type: field.type,
      })),
    )
    .flat()
    .filter((field) => selectedFields.includes(field.fieldName));
  const fieldsUnique = fields.reduce(
    (items, item) =>
      items.find((x: any) => x.fieldName === item.fieldName)
        ? [...items]
        : [...items, item],
    [],
  );

  return (
    <TabPane
      key={DocumentAppConfig.todixId}
      tab={
        <>
          <DocumentAppConfig.mainMenu.icon />
          <FormattedMessage
            id="app.documents.related"
            defaultMessage="Related documents"
          />
        </>
      }
    >
      <AppView
        breadcrumbItems={[
          [
            `/app/${DocumentAppConfig.todixId}/archive`,
            intl.formatMessage({
              id: 'app.documents',
              defaultMessage: 'Documents',
            }),
          ],
        ]}
        columns={columns(fieldsUnique, intl)}
        getRows={getGetRows(
          [
            'id',
            'uploadingUser',
            'creatingUser',
            'documentUrl',
            ...fieldsUnique.map((entry: any) => entry.fieldName),
          ],
          intl,
          relatedDocuments,
        )}
        sideContent={SideContent}
        path={`/app/${DocumentAppConfig.todixId}`}
        onCellClickPath={`/app/${DocumentAppConfig.todixId}`}
        tabs={['list']}
        options={optionsMapper(['open'], intl)}
        hideQuickFilter
      />
    </TabPane>
  );
};

export default getArchivedDocumentsTab;
