import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';
import { WEEK_PLAN_COLLECTION_NAME } from '@apps/weekplan/services/namespace';
export default {
  todixId: 'weekplan',
  link: '/app/weekplan',
  name: 'weekplan',
  entityType: 'weekplan',
  collectionName: WEEK_PLAN_COLLECTION_NAME,
  localizationPath: 'weekplan/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.weekplan.createNewLink.new.weekplan',
    },
  ],
  listCellRenderers: [
    {
      name: 'lanesRenderer',
      path: 'weekplan/widgets/LaneCellRenderer',
    },
  ],
  widgets: [
    {
      name: 'TreeNode',
      path: 'weekplan/widgets/TreeNode',
    },
    {
      name: 'StatisticsWidget',
      path: 'weekplan/widgets/StatisticsWidget',
    },
    {
      name: 'DashboardWidget',
      path: 'weekplan/widgets/DashboardWidget',
    },
  ],
} as FileApp;
