import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { Tabs, Spin, message } from 'antd';
import { Pie } from '@ant-design/charts';
import { DashboardOutlined } from '@ant-design/icons';

import { FormattedMessage, useIntl } from 'react-intl';
import { WeekPlanEntry } from '@apps/weekplan/services/namespace';
import getWeekPlanById from '@apps/weekplan/services/getWeekPlanById';
import WeekPlanAppConfig from '@apps/weekplan';
import optionsMapper from '@core/helpers/optionsMapper';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import deleteWeekPlan from '@apps/weekplan/services/deleteWeekPlan';

const { TabPane } = Tabs;

const SideContent: FC<{ id: string }> = ({ id }) => {
  const intl = useIntl();
  const [weekPlan, setWeekPlan] = useState<WeekPlanEntry | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchWeekPlan = useCallback(async (weekPlanId) => {
    const fetchedWeekPlan = await getWeekPlanById(weekPlanId);
    setWeekPlan(fetchedWeekPlan);
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchWeekPlan(id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [fetchWeekPlan, id]);

  const lanes = useMemo(() => {
    if (weekPlan) {
      return [
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.monday.header',
            defaultMessage: 'Monday',
          }),
          value: weekPlan.lanes[0].cards.length,
        },
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.tuesday.header',
            defaultMessage: 'Tuesday',
          }),
          value: weekPlan.lanes[1].cards.length,
        },
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.wednesday.header',
            defaultMessage: 'Wednesday',
          }),
          value: weekPlan.lanes[2].cards.length,
        },
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.thursday.header',
            defaultMessage: 'Thursday',
          }),
          value: weekPlan.lanes[3].cards.length,
        },
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.friday.header',
            defaultMessage: 'Friday',
          }),
          value: weekPlan.lanes[4].cards.length,
        },
        {
          type: intl.formatMessage({
            id: 'app.weekplan.columns.freeTasks.header',
            defaultMessage: 'Free tasks',
          }),
          value: weekPlan.lanes[5].cards.length,
        },
      ];
    }
    return [];
  }, [intl, weekPlan]);

  const config = {
    width: 500,
    height: 500,
    autoFit: false,
    appendPadding: 10,
    data: lanes,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'element-active' }],
  };
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        <ActionRenderer
          path={`/app/${WeekPlanAppConfig.todixId}/${id}`}
          options={optionsMapper(['edit', 'delete'], intl)}
          handleDelete={() => {
            deleteWeekPlan(id)?.catch(() => {
              message.error(
                intl.formatMessage({
                  id: 'app.weekplan.delete.error',
                  defaultMessage: 'Error while deleting week plan',
                }),
              );
            });
          }}
          intl={intl}
        />
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="tasks.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        <Spin spinning={isLoading}>
          {weekPlan && weekPlan.name}
          <Pie {...config} />
        </Spin>
      </TabPane>
    </Tabs>
  );
};

export default SideContent;
