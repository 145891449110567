import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { getDocument, updateDocument, Document } from '@apps/documents/service';
import DetailedDocument from '@apps/documents/mainMenu/DetailedDocument';
import { useIntl } from 'react-intl';

type Props = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
};

const Edit: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [details, setDetails] = useState<any>(null);
  const intl = useIntl();
  useEffect(() => {
    getDocument(id)?.then(setDetails);
  }, [id]);
  return (
    <DetailedDocument
      id={id}
      // @ts-ignore
      initialValues={details}
      readOnly={false}
      onSubmit={(values: Partial<Document>) => {
        updateDocument(id, {
          ...values,
          documentUrl: details.documentUrl,
          fileDetails: details.fileDetails || null,
          creatingUser: details.creatingUser || '',
          uploadingUserId: details.uploadingUserId || '',
        })
          ?.then(() => {
            props.history.push('/documents');
            // message.success(
            //   intl.formatMessage({
            //     id: 'documents.edit.success',
            //     defaultMessage: 'Document updated',
            //   }),
            // );
          })
          .catch(() => {
            message.error(
              intl.formatMessage({
                id: 'documents.edit.error',
                defaultMessage: 'Error while document creating',
              }),
            );
          });
      }}
    />
  );
};

export default Edit;
