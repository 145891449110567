import { GridApi } from 'ag-grid-community';
import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import {
  ActionRenderer,
  ActionRendererProps,
} from '@components/AppView/ActionRenderer';

export type ListActionRendererProps = {
  data: {
    id: string;
    isActive?: boolean;
  };
  api: GridApi;
  context?: any;
};

const ListActionRenderer = (props: ListActionRendererProps) => {
  const intl = useIntl();
  const { context } = props;
  const { getExtraOptions, onRemoveItem, options } = context;
  const passedOptions: ActionRendererProps['options'] = options || [
    'open',
    'edit',
    'delete',
  ];

  const refreshList = useCallback(() => {
    props.api.deselectAll();
    props.api.purgeInfiniteCache();
  }, []);

  if (!props.data) {
    return '';
  }

  return (
    <ActionRenderer
      path={`${context.path}/${props.data.id}`}
      options={passedOptions}
      extraOptions={getExtraOptions && getExtraOptions(props.data, refreshList)}
      handleDelete={() => {
        try {
          onRemoveItem(props.data.id)?.then(() => {
            props.api.deselectAll();
            props.api.purgeInfiniteCache();
          });
        } catch (e) {
          console.error(e);
          props.api.deselectAll();
          props.api.purgeInfiniteCache();
        }
      }}
      intl={intl}
    />
  );
};

export default ListActionRenderer;
