import React, { FC, useState } from 'react';
import FlowManagementForm from '@apps/warehouse/mainMenu/components/FlowManagementForm';
import { ProductEntry } from '@apps/warehouse/services/namespace';
import ProductEntries from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries';

type FlowManagementProps = {
  onSubmit(values: any): void;
  ecosystemId?: string;
};

const FlowManagement: FC<FlowManagementProps> = ({
  onSubmit,
  ecosystemId = null,
}) => {
  const [selectedEcosystem, setSelectedEcosystem] = useState<string | null>(
    ecosystemId,
  );
  const [products, setProducts] = useState<ProductEntry[]>([]);
  const [count, setCount] = useState(0);
  return (
    <>
      <FlowManagementForm
        entries={products}
        setEntries={setProducts}
        onSubmit={onSubmit}
        onEcosystemSelect={setSelectedEcosystem}
        ecosystemId={ecosystemId || ''}
      />
      <ProductEntries
        entries={products}
        setEntries={setProducts}
        count={count}
        setCount={setCount}
        selectedEcosystem={selectedEcosystem}
      />
    </>
  );
};

export default FlowManagement;
