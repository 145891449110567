import {
  COMMISSIONS_TOTALS_COLLECTION,
  ConsultantCommissionState,
} from '@apps/directSales/services/commisions/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';
import { firebase } from '@services/Firebase';

const getLatestCommissionPeriodStatsForConsultant = (
  ecosystemId: string,
  consultantId: string,
) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve(null);
  }

  return firebase.firestore
    ?.collection(COMMISSIONS_TOTALS_COLLECTION)
    .where('ecosystemId', '==', ecosystemId)
    .where('consultantId', '==', consultantId)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as ConsultantCommissionState[]) : [];
    });
};

export default getLatestCommissionPeriodStatsForConsultant;
