import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';

export default {
  todixId: 'documents',
  link: '/app/documents',
  name: 'documents',
  collectionName: 'documents',
  localizationPath: 'documents/i18n',
  entityType: 'document',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.documents.createNewLink.new.document',
    },
  ],
  listCellRenderers: [
    {
      name: 'documentField',
      path: 'documents/widgets/DocumentFieldRenderer',
    },
  ],
  widgets: [
    {
      name: 'AddArchivedDocument',
      path: 'documents/widgets/AddArchivedDocument',
    },
    {
      name: 'InboxUploadWidget',
      path: 'documents/widgets/InboxUploadWidget',
    },
    {
      name: 'TreeNode',
      path: 'documents/widgets/TreeNode',
    },
    {
      name: 'DashboardWidget',
      path: 'documents/widgets/DashboardWidget',
    },
    {
      name: 'StatisticsWidget',
      path: 'documents/widgets/StatisticsWidget',
    },
  ],
} as FileApp;
