import React, { CSSProperties, FC } from 'react';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { View } from '@react-pdf/renderer';
import ProductsTablePDF from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsTablePDF';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import ProductsTable from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsTable';
import ProductsSummary from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsSummary';
import { DocumentType } from '@apps/documents/service';

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  products: SalesFormValues['products'];
  ecosystem: string;
  documentType: DocumentType;
};

const Products: FC<Props> = ({
  forPdf,
  style,
  products,
  intl,
  ecosystem,
  documentType,
}) => {
  if (forPdf && products) {
    return (
      <View style={style}>
        <ProductsTablePDF products={products} intl={intl} />
        <ProductsSummary
          products={products}
          intl={intl}
          forPdf
          documentType={documentType}
        />
      </View>
    );
  }

  return (
    <div style={{ ...(style as CSSProperties) }}>
      <ProductsTable
        products={products}
        intl={intl}
        ecosystem={ecosystem}
        documentType={documentType}
      />
      <ProductsSummary
        products={products}
        intl={intl}
        documentType={documentType}
      />
    </div>
  );
};

export default Products;
