import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { PRODUCTS_COLLECTION_NAME } from '@apps/products/services';

export default {
  todixId: 'products',
  link: '/app/products',
  name: 'products',
  entityType: 'product',
  collectionName: PRODUCTS_COLLECTION_NAME,
  localizationPath: 'products/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.products.createNewLink.new.product',
    },
  ],
  widgets: [
    {
      name: 'TreeNode',
      path: 'products/widgets/TreeNode',
    },
    {
      name: 'DashboardWidget',
      path: 'products/widgets/DashboardWidget',
    },
    {
      name: 'StatisticsWidget',
      path: 'products/widgets/StatisticsWidget',
    },
  ],
};
