import SettingsConfig from '@apps/warehouse/settings';
import MainMenuConfig from '@apps/warehouse/mainMenu';
import { FileApp } from '@apps/AppTypes';

export default {
  todixId: 'warehouse',
  link: '/app/warehouse',
  name: 'warehouse',
  entityType: 'warehouse',
  collectionName: 'warehouse',
  localizationPath: 'warehouse/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.warehouse',
    },
  ],
  widgets: [],
} as FileApp;
