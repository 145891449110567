import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import AppView from '@components/AppView';
import optionsMapper from '@core/helpers/optionsMapper';
import columns from '@apps/projects/mainMenu/columns';
import SideContent from '@apps/projects/mainMenu/components/SideContent';
import getProjects from '@apps/projects/services/getProjects';
import deleteProject from '@apps/projects/services/deleteProject';

const Projects: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getProjects({
      query: params.query,
      offset: params.startRow,
      limit: params.endRow - params.startRow,
      fields: [
        'isFav',
        'id',
        'name',
        'ecosystem',
        'managerId',
        'membersIds',
        'creator',
        'color',
        'startDate',
        'endDate',
        'children',
      ],
      filters: Object.fromEntries(filtersEntries),
      sort: params.sortModel.map(
        (model: { colId: string; sort: string }) =>
          `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
      ),
    })?.then((response) => {
      params.successCallback(response.results, response.info.results);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.projects.list.title',
            defaultMessage: 'Projects',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={deleteProject}
      options={optionsMapper(['edit', 'delete'], intl)}
      actionListCells={['favProjectCellRenderer']}
    />
  );
};

export default Projects;
