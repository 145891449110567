import {
  auth,
  apps,
  initializeApp,
  User,
  Unsubscribe,
  app,
  storage,
  firestore,
  functions,
  database,
} from 'firebase/app';
import firebaseApp from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';
import { rootStore } from '@core/store';
import getFirebaseUser from '@core/helpers/getFirebaseUser';
import { SetUserAction } from '@core/store/user';
import {
  getEcosystemRelationsByMemberId,
  getEcosystems,
} from '@services/ecosystems';
import { checkIfPhotoAvailable } from '@services/photos';
import { getMultipleEcosystemProductGroups } from '@services/productGroups';
import { fetchUser } from '@services/users';
import licencesListener from '@services/licences/licencesListener';
import notificationListener from '@services/notifications/notificationListener';
import ecosystemsListeners from '@services/ecosystems/ecosystemsListeners';
import ecosystemRelationsListener from '@services/ecosystems/ecosystemRelationsListener';
import getCart from '@services/cart/getCart';
import { InitCartAction } from '@core/store/cart';
import stripe from '@services/stripe';
import { SetStripeProducts } from '@core/store/stripeProducts/actions';
import getUserPreferences from '@services/userPreferences/getUserPreferences';
import { UserPreferencesActionTypes } from '@core/store/userPreferences/actions';
import permissionsListener from '@services/permissions/permissionsListener';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
};

export class Firebase {
  app?: app.App;
  auth: auth.Auth;
  user?: User | null;
  firestore?: firestore.Firestore;
  functions?: functions.Functions;
  db?: database.Database;

  constructor() {
    if (!apps.length) {
      this.app = initializeApp(config);

      // state is persist when the browser window is closed
      auth().setPersistence(auth.Auth.Persistence.LOCAL);
    }

    this.auth = auth();
    this.firestore = this.app?.firestore();
    this.db = this.app?.database();
    this.functions = this.app?.functions();
    this.auth.useDeviceLanguage();
    this.auth.onAuthStateChanged(async (user) => {
      const stripeProducts = await stripe.getProducts();
      //TODO: support pagination
      rootStore.dispatch({
        type: 'SET_STRIPE_PRODUCTS',
        payload: stripeProducts.data,
      } as SetStripeProducts);
      this.user = user;
      if (user) {
        const isPhotoAvailable = await checkIfPhotoAvailable(user.photoURL);
        const userData = await fetchUser(user.uid);

        if (this.db && user.email) {
          await licencesListener(user.uid, this.db);
          notificationListener(user.email, this.db);
          ecosystemRelationsListener(user.uid, this.db);
        }

        const newUser = { ...user };

        if (!isPhotoAvailable) {
          let defaultAvatar: string;
          try {
            defaultAvatar = await this.getDefaultProfileAvatar();
          } catch {
            console.error('Cant retrieve default profile avatar');
          }
          // @ts-ignore
          newUser.photoURL = defaultAvatar;
        }

        rootStore.dispatch({
          type: 'SET_USER',
          payload: { ...userData, ...getFirebaseUser(newUser) },
        } as SetUserAction);

        this.initEcosystems().then(() => {
          if (this.user?.uid) {
            getCart(this.user.uid).then((cart) => {
              if (cart) {
                rootStore.dispatch({
                  type: 'INIT_CART',
                  payload: cart,
                } as InitCartAction);
              }
            });
          }
        });

        await this.fetchUserPreferencesInEcosystems(user.uid);
      } else {
        rootStore.dispatch({
          type: 'SET_USER',
          payload: null,
        } as SetUserAction);
        this.db?.ref('licences').off();
        this.db?.ref('invitations').off();
        this.auth.signOut();
      }
    });
    // @ts-ignore
    window.firebase = firebaseApp;
  }

  // needed to be here because of circular dependency
  getUrl = async (path: string): Promise<any> => {
    const storage = this.getStorageRef();
    if (!storage) {
      return {
        error: 'No storage ref defined',
        status: 'failed',
      };
    }

    return storage.child(path);
  };

  getDefaultProfileAvatar = async () => {
    const avatarAmount = 5;
    const avatarIndex = Math.floor(Math.random() * avatarAmount) + 1;

    return await (
      await this.getUrl(`public/avatars/avatar${avatarIndex}.svg`)
    ).getDownloadURL();
  };

  getDefaultEcosystemAvatar = async () => {
    const avatarAmount = 5;
    const avatarIndex = Math.floor(Math.random() * avatarAmount) + 1;

    return (
      await this.getUrl(`public/avatars/avatarCompany${avatarIndex}.svg`)
    ).getDownloadURL();
  };

  getStorageRef = (): storage.Reference | undefined =>
    this.app?.storage().ref();

  // used in verification the action code for email confirmation and password reset
  applyActionCode = (actionCode: string): Promise<void> =>
    auth().applyActionCode(actionCode);

  // listener for auth state changes
  onAuthStateChanged = (observer: (user: User | null) => any): Unsubscribe =>
    auth().onAuthStateChanged(observer);

  // listener for id token changes
  onIdTokenChanged = (observer: (user: User | null) => any): Unsubscribe =>
    auth().onIdTokenChanged(observer);

  getCurrentUser = (): User | null => auth().currentUser;

  initEcosystems = async (onlyEcosystemChanges = false) => {
    if (!this.user?.uid) {
      return;
    }

    const ecosystemRelations = await getEcosystemRelationsByMemberId(
      this.user?.uid as string,
    );

    rootStore.dispatch({
      type: 'SET_RELATIONS',
      payload: ecosystemRelations || [],
    });

    if (ecosystemRelations) {
      const onlyEnabledRelations = ecosystemRelations.filter(
        (relation) => !relation.disabled,
      );

      const activeEcosystemIds = onlyEnabledRelations.map(
        (relation) => relation.ecosystem,
      );

      const ecosystemsUids = onlyEnabledRelations.map(
        (relation) => relation.ecosystem,
      );

      getEcosystems(ecosystemsUids)?.then(async (ecosystems) => {
        const productGroups = await getMultipleEcosystemProductGroups(
          ecosystemsUids,
        );

        let defaultAvatar: string;
        try {
          defaultAvatar = await this.getDefaultEcosystemAvatar();
        } catch {
          console.error('Cant retrieve default ecosystem avatar');
        }

        const ecosystemsPromises = ecosystems.map(async (ecosystem) => {
          const isPhotoAvailable = await checkIfPhotoAvailable(
            ecosystem.avatarUrl,
          );

          const relation = onlyEnabledRelations.find(
            (relation) => relation.ecosystem === ecosystem.id,
          );

          return {
            ...ecosystem,
            isActive:
              activeEcosystemIds.includes(ecosystem.id) && relation?.isActive,
            avatarUrl: isPhotoAvailable ? ecosystem.avatarUrl : defaultAvatar,
          };
        });

        const defaultEcosystems = await Promise.all(ecosystemsPromises);

        // to prevent rendering when there is no change
        if (onlyEcosystemChanges) {
          const usedEcosystemIds = rootStore
            .getState()
            .ecosystems.map((el: Ecosystem) => el.id);

          const newEcosystemIds = defaultEcosystems.map(
            (el: Ecosystem) => el.id,
          );

          const areEcosystemChanged =
            !!usedEcosystemIds.find(
              (id: string) => !newEcosystemIds.includes(id),
            ) ||
            !!newEcosystemIds.find(
              (id: string) => !usedEcosystemIds.includes(id),
            );

          if (!areEcosystemChanged) {
            return;
          }
        }

        rootStore.dispatch({
          type: 'SET_ECOSYSTEMS',
          payload: defaultEcosystems || [],
        });

        rootStore.dispatch({
          type: 'SET_PRODUCT_GROUPS',
          payload: productGroups || [],
        });

        if (this.db) {
          const ecoIds = defaultEcosystems.map((el: Ecosystem) => el.id);
          ecosystemsListeners(ecoIds, this.db);
          permissionsListener(this.user?.uid as string, this.db);
        }
      });
    }
  };

  fetchUserPreferencesInEcosystems = async (userId: string) => {
    const preferences = await getUserPreferences(userId);

    rootStore.dispatch({
      type: UserPreferencesActionTypes.UPDATE_USER_PREFERENCES,
      payload: preferences,
    });
  };
}

export default new Firebase();
