import * as React from 'react';
import { Form, Input } from 'antd';
import { Radio, RadioGroup } from '@components/FormFields';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';

import SelectedEcosystem from '@apps/contacts/mainMenu/components/DetailContactForm/SelectedEcosystem';
import { CommonContactFields } from '@apps/contacts/services';
import PersonInfo from '@apps/contacts/mainMenu/components/DetailContactForm/PersonInfo';
import CompanyInfo from '@apps/contacts/mainMenu/components/DetailContactForm/CompanyInfo';
import AssetUpload from '@apps/contacts/mainMenu/components/DetailContactForm/AssetUpload';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';

export const initialGeneralInfo: Pick<CommonContactFields, 'type' | 'avatar'> =
  {
    type: 'person',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/simple-avatar.svg?alt=media&token=3842cd57-6fdb-4109-9301-6d92786e9b41',
  };

const GeneralInformation: React.FC = () => {
  const { readOnly } = useFormContext();
  const intl = useIntl();

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, nextValues) =>
        prevValues.type !== nextValues.type
      }
    >
      {({ getFieldValue }) => {
        const contactType = getFieldValue('type');
        const isCompany = contactType === 'company';
        const isPerson = contactType === 'person';
        return (
          <div>
            <Form.Item
              name="type"
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="detailcontactform.generalinformation.type.error"
                      defaultMessage="Missing contact type"
                    />
                  ),
                },
              ]}
            >
              <RadioGroup name="type" disabled={readOnly}>
                <Radio
                  inGroup
                  name="type"
                  value="company"
                  label={
                    <span>
                      <HomeOutlined />{' '}
                      <FormattedMessage
                        id="detailcontactform.generalinformation.type.company"
                        defaultMessage="Company"
                      />
                    </span>
                  }
                />
                <Radio
                  inGroup
                  name="type"
                  value="person"
                  label={
                    <span>
                      <UserOutlined />{' '}
                      <FormattedMessage
                        id="detailcontactform.generalinformation.type.person"
                        defaultMessage="Person"
                      />
                    </span>
                  }
                />
              </RadioGroup>
            </Form.Item>
            <SelectedEcosystem />
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, nextValues) =>
                prevValues.type !== nextValues.type
              }
            >
              {({ getFieldValue }) => {
                const type = getFieldValue('type');
                return type === 'person' ? <PersonInfo /> : <CompanyInfo />;
              }}
            </Form.Item>
            <AssetUpload
              fieldName="avatar"
              label={
                isPerson
                  ? intl.formatMessage({
                      id: 'detailcontactform.avatarupload.label.avatar',
                      defaultMessage: 'Image/avatar',
                    })
                  : intl.formatMessage({
                      id: 'detailcontactform.avatarupload.label.logo',
                      defaultMessage: 'Logo',
                    })
              }
            />
            {isCompany && (
              <Form.Item
                name="invoiceEmail"
                label={
                  <FormattedMessage
                    id="detailcontactform.generalinformation.invoiceemail.label"
                    defaultMessage="Invoice email"
                  />
                }
                rules={[
                  {
                    type: 'email',
                    message: (
                      <FormattedMessage
                        id="detailcontactform.generalinformation.invoiceemail.error"
                        defaultMessage="Must be valid email address"
                      />
                    ),
                  },
                ]}
              >
                <Input
                  name="invoiceEmail"
                  placeholder={intl.formatMessage({
                    id: 'detailcontactform.generalinformation.invoiceemail.placeholder',
                    defaultMessage: 'Invoice email',
                  })}
                  readOnly={readOnly}
                />
              </Form.Item>
            )}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default GeneralInformation;
