import React, { FC, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const Projects = lazy(
  () => import(/* webpackChunkName: "projectsApp" */ './Projects'),
);

const ProjectDetails = lazy(
  () => import(/* webpackChunkName: "projectsApp" */ './ProjectDetails'),
);

const AppRouting: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <ProjectDetails />
      </Route>
      <Route path={`${path}/:id`}>
        <ProjectDetails />
      </Route>
      <Route path={`${path}`}>
        <Projects />
      </Route>
    </Switch>
  );
};

export default AppRouting;
