import * as React from 'react';
import { useIntl } from 'react-intl';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig from '@apps/documents';
import {
  ButtonContainer,
  GridSection,
  DocumentSpecificGridSection,
  LeftColumn,
  RightColumn,
  DocumentWrapper,
} from '@apps/documents/mainMenu/Inbox/DocumentProcessing/styles.sc';
import HiddenFields from '@apps/documents/mainMenu/Inbox/DocumentProcessing/HiddenFields';
import DocumentType from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentType';
import DocumentCategory from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentCategory';
import RelatedContact from '@apps/documents/mainMenu/Inbox/DocumentProcessing/RelatedContact';
import DocumentSpecificFields from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentSpecificFields';
import { Button, Tag } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { styled } from '@styles/themes';
import { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Document } from '@apps/documents/service';
import SpinContainer from '@components/SpinContainer';
import {
  StackedViewContent,
  StackedViewLayout,
  StackedViewSider,
} from '@components/AppView/AppView.sc';
import MediaViewer from '@components/MediaViewer';
import { StyledCard } from '@components/AppView/SideView';

const FormContainer = styled.div``;

type BaseTabProps = {
  id?: string;
  initialValues: Partial<Document>;
  onSubmit?: (v: any) => void;
  readOnly?: boolean;
};

const BaseTab: React.FC<BaseTabProps> = ({
  id,
  initialValues,
  readOnly = true,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const intl = useIntl();
  const [form] = useForm();
  const history = useHistory();

  const handleCancel = useCallback(() => {
    history.push(`/app/${AppConfig.todixId}/archive`);
  }, [history]);

  const trigger = useMemo(() => {
    return (
      <Tag
        color="blue"
        icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
      >
        {collapsed
          ? intl.formatMessage({
              id: 'show.document',
              defaultMessage: 'Show document',
            })
          : intl.formatMessage({
              id: 'hide.document',
              defaultMessage: 'Hide document',
            })}
      </Tag>
    );
  }, [intl, collapsed]);

  if (Object.keys(initialValues).length < 1) {
    return <SpinContainer />;
  }

  return (
    <DocumentWrapper>
      <StackedViewLayout
        style={
          collapsed
            ? { gridTemplateColumns: '0fr 1fr' }
            : { gridTemplateColumns: '0.6fr 0.4fr' }
        }
      >
        <StackedViewSider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={trigger}
          width={'100%'}
        >
          <MediaViewer
            items={[
              {
                url: initialValues.documentUrl || '',
                thumbnailUrl:
                  initialValues?.fileDetails?.format === 'pdf'
                    ? initialValues.thumbnailUrl || 'placeholder'
                    : initialValues.documentUrl,
                format: initialValues?.fileDetails?.format || '',
              },
            ]}
            width={'100vw'}
            height={'100vh'}
            showPlayButton={false}
            showNav={false}
            thumbnailPosition="left"
          />
        </StackedViewSider>
        <StackedViewContent>
          <StyledCard>
            <AppEntityForm
              elId={id}
              appId={AppConfig.todixId}
              autoComplete="off"
              initialValues={initialValues}
              name="documentArchive"
              providedForm={form}
              readOnly={readOnly}
              style={{ height: `calc(100vh - 280px)` }}
              hideButtons={true}
            >
              {() => {
                return (
                  <div>
                    <FormContainer>
                      <GridSection>
                        <LeftColumn>
                          <HiddenFields />
                          <DocumentType />
                          <DocumentCategory />
                        </LeftColumn>
                        <RightColumn>
                          <RelatedContact />
                        </RightColumn>
                      </GridSection>
                      <DocumentSpecificGridSection>
                        <DocumentSpecificFields initialValues={initialValues} />
                      </DocumentSpecificGridSection>
                    </FormContainer>
                  </div>
                );
              }}
            </AppEntityForm>
            <ButtonContainer style={{ justifyContent: 'end' }}>
              <div>
                <Button type="default" htmlType="button" onClick={handleCancel}>
                  {intl.formatMessage({
                    id: 'documents.details.form.readonlycancelbutton.label',
                    defaultMessage: 'Back to list',
                  })}
                </Button>
              </div>
            </ButtonContainer>
          </StyledCard>
        </StackedViewContent>
      </StackedViewLayout>
    </DocumentWrapper>
  );
};

export default BaseTab;
