import React, { FC } from 'react';
import { ProductWithPrice } from '@apps/sales/services';
import { ProductPrice } from '@apps/products/services';
import { useIntl } from 'react-intl';
import { NumericFormatProps } from 'react-number-format';
import { Form } from 'antd';
import { ItemNoMargin, StyledMoneyInput } from './styles.sc';

const { Item } = Form;

type Props = NumericFormatProps & {
  selectedEcosystem?: string | null;
  product: ProductWithPrice;
  prices: ProductPrice[];
};

const calculateValues = (
  priceValue: number,
  formValues: Record<string, any>,
) => {
  const { discount, taxValue, amount } = formValues;
  const price = priceValue !== undefined ? priceValue : 0;
  const discountedPrice = Number(
    Number(price - (Number(discount) / 100) * price).toFixed(2),
  );
  const grossPrice = Number(
    Number(discountedPrice + discountedPrice * (taxValue / 100)).toFixed(2),
  );
  const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
  const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

  return {
    price,
    discountedPrice,
    grossPrice,
    totalNetPrice,
    totalGrossPrice,
  };
};

const PriceInOrder: FC<Props> = ({ prices, product, ...rest }) => {
  const intl = useIntl();
  const defaultPrice = prices.find((price) => price.isDefaultPrice);
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name="price">
            <StyledMoneyInput
              currency={defaultPrice?.currency || 'EUR'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.price.label',
                defaultMessage: 'Price',
              })}
              onValueChange={({ floatValue = 0 }) => {
                const newValues = calculateValues(floatValue, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });
              }}
              {...rest}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default PriceInOrder;
