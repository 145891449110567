import React, { FC } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

type AddCardLinkProps = {
  onClick(): void;
};

const AddCardLink: FC<AddCardLinkProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <FormattedMessage
        id="app.weekplan.addCardLink"
        defaultMessage="Add new task"
      />
    </Button>
  );
};

export default AddCardLink;
