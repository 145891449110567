import React, { FC, useCallback } from 'react';
import moment from 'moment';
import { Form, Input } from 'antd';
import GeneralInformation, {
  initialGeneralInfo,
} from '@apps/contacts/mainMenu/components/DetailContactForm/GeneralInformation';
import BankAccount from '@apps/contacts/mainMenu/components/DetailContactForm/BankAccount';
import { initialTags } from '@apps/contacts/mainMenu/components/DetailContactForm/SelectedTags';
import { initialRelatedContacts } from '@apps/contacts/mainMenu/components/DetailContactForm/RelatedContacts';
import { initialTeam } from '@apps/contacts/mainMenu/components/DetailContactForm/Team';
import { CompanyContact, PersonContact } from '@apps/contacts/services';
import CompanyDetails from '@apps/contacts/mainMenu/components/DetailContactForm/CompanyDetails';
import SpinContainer from '@components/SpinContainer';
import {
  Column,
  FormContainer,
  MaxItem,
} from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppEntityForm,
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import ContactsAppConfig from '@apps/contacts';
import Address from '@apps/contacts/mainMenu/components/DetailContactForm/Address';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { emitCustomEvent } from '@core/services';
import { useForm } from 'antd/lib/form/Form';
import PhoneNumber from '@apps/contacts/mainMenu/components/DetailContactForm/PhoneNumber';
import Email from '@apps/contacts/mainMenu/components/Email';

const defaultValues: Partial<PersonContact & CompanyContact> = {
  ...initialGeneralInfo,
  ...initialTags,
  ...initialRelatedContacts,
  ...initialTeam,
  standardInvoiceAddress: 0,
  standardShippingAddress: 0,
  standardAddress: 0,
};

export type DetailContactFormProps = {
  id?: string;
  initialValues: Partial<PersonContact & CompanyContact>;
  onSubmit?: (v: PersonContact | CompanyContact) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  shouldRedirect?: boolean;
};

const DetailContactForm: FC<DetailContactFormProps> = ({
  id,
  initialValues: initialValuesProp,
  onSubmit,
  readOnly = true,
  shouldRedirect = true,
  onCancel,
}) => {
  const [form] = useForm();
  const intl = useIntl();

  const onFinishFailed = useCallback((errorInfo: ValidateErrorEntity) => {
    emitCustomEvent(ON_FINISH_FAILED_EVENT, {
      errorInfo,
    } as OnFinishFailedEvent);
  }, []);

  if (initialValuesProp === null) {
    return <SpinContainer />;
  }

  const initialValues = {
    ...defaultValues,
    ...initialValuesProp,
    birthDate:
      initialValuesProp.birthDate && moment(initialValuesProp.birthDate),
    foundationDate:
      initialValuesProp.foundationDate &&
      moment(initialValuesProp.foundationDate, moment.defaultFormat),
  };

  return (
    <AppEntityForm
      elId={id}
      autoComplete="off"
      initialValues={initialValues}
      appId={ContactsAppConfig.todixId}
      name="contact"
      onSubmit={onSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={() => {
        form.validateFields().catch((res) => {
          onFinishFailed(res);
        });
      }}
      onCancel={onCancel}
      readOnly={readOnly}
      shouldRedirect={shouldRedirect}
      providedForm={form}
    >
      {() => {
        return (
          <FormContainer>
            <div>
              <GeneralInformation />
            </div>
            <Column>
              <Form.Item
                shouldUpdate={(prevValues, nextValues) =>
                  prevValues.type !== nextValues.type
                }
                noStyle
              >
                {({ getFieldValue }) => {
                  const contactType = getFieldValue('type');
                  return contactType === 'company' ? <CompanyDetails /> : null;
                }}
              </Form.Item>
              <Address />
              <PhoneNumber />
              <Email />
              {/*<Form.Item shouldUpdate>
                {() => <RelatedContacts selectedContactId={id} />}
              </Form.Item>*/}
              <BankAccount />
              <MaxItem
                name="memo"
                rules={[
                  {
                    type: 'string',
                    max: 500,
                    message: (
                      <FormattedMessage
                        id="detailcontactform.generalinformation.memo.error"
                        defaultMessage="Memo cannot be longer than 500 characters"
                      />
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  name="memo"
                  placeholder={intl.formatMessage({
                    id: 'detailcontactform.generalinformation.memo.label',
                    defaultMessage: 'Memo',
                  })}
                  readOnly={readOnly}
                />
              </MaxItem>
            </Column>
          </FormContainer>
        );
      }}
    </AppEntityForm>
  );
};

export default DetailContactForm;
