import { getWithId, patch, post } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import { firebase } from '@services/Firebase';
import {
  ConsultantLevelType,
  DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME,
} from '@apps/directSales/services/consultantLevel/namespace';
import moment from 'moment/moment';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';

export * from './namespace';

export const postConsultantLevel = (consultantLevel: ConsultantLevelType) =>
  post(`${DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME}`, {
    ...removeUndefinedProperties(consultantLevel),
    timestamp: moment().valueOf(),
  });

export const updateConsultantLevel = (
  id: string,
  consultantLevel: ConsultantLevelType,
) =>
  patch(`${DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME}/${id}`, {
    ...removeUndefinedProperties(consultantLevel),
    timestamp: moment().valueOf(),
  });

export const getActiveConsultantLevels = (
  ecosystemId: string,
): Promise<ConsultantLevelType[]> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([] as ConsultantLevelType[]);
  }

  return firebase.firestore
    ?.collection(DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .where('isActive', '==', true)
    .get()
    .then(getArrayWithIds) as Promise<ConsultantLevelType[]>;
};

export const getConsultantLevels = (
  ecosystemId: string,
): Promise<ConsultantLevelType[]> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([] as ConsultantLevelType[]);
  }

  return firebase.firestore
    ?.collection(DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as ConsultantLevelType[]) : [];
    });
};

export const getConsultantLevel = (id: string) =>
  getWithId(
    `${DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME}/${id}`,
  ) as Promise<ConsultantLevelType>;
