import React, { FC, useState, useEffect, CSSProperties } from 'react';
import { Spin, Space } from 'antd';
import { Sale } from '@apps/sales/services';
import { DocumentType } from '@apps/documents/service';
import {
  getSalesOperationsForSale,
  SaleOperationStatus,
} from '@apps/sales/services/saleOperation';
import stepTypeIconMapper from '@apps/sales/mainMenu/stepTypeIconMapper';
import saleStatusColors from '@apps/sales/mainMenu/saleStatusColors';
import { StatusIconContainer } from '@apps/sales/mainMenu/styles.sc';

type Props = {
  data?: Sale;
  value?: string;
};

const stepStatuses = Object.keys(stepTypeIconMapper).reduce((acc, item) => {
  return {
    ...acc,
    [item]: 'empty' as SaleOperationStatus,
  };
}, {});

const StepsStatusCellRenderer: FC<Props> = ({ value, data }) => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<Record<DocumentType, SaleOperationStatus>>(
    {},
  );

  useEffect(() => {
    if (value && data?.ecosystem) {
      setLoading(true);
      getSalesOperationsForSale(value, data.ecosystem)
        ?.then((stepOperations) => {
          let updatedSteps: Record<DocumentType, SaleOperationStatus> = {};
          stepOperations.forEach((item) => {
            updatedSteps = {
              ...updatedSteps,
              [item.step]: item.status,
            };
          });
          setSteps({
            ...stepStatuses,
            ...updatedSteps,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [data?.ecosystem, value]);

  return (
    <Spin spinning={loading}>
      <Space>
        {Object.keys(steps).map((step, index) => {
          const StepIcon: FC<{ style: CSSProperties }> =
            stepTypeIconMapper[step];
          const status = steps[step];
          const color = saleStatusColors[status];
          return (
            <StatusIconContainer
              style={{ backgroundColor: color || '#8c8c8c' }}
            >
              <StepIcon key={index} style={{ color: '#fff' }} />
            </StatusIconContainer>
          );
        })}
      </Space>
    </Spin>
  );
};

export default StepsStatusCellRenderer;
