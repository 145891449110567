import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Descriptions,
  AutoComplete,
  Input,
  Row,
  Col,
  Avatar,
  Collapse,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  getContacts,
  ApiResponse,
  Contact,
  Email,
  AddressEntry,
} from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilterTextObject } from '@services/filtering';

const { Panel } = Collapse;

const AutoCompleteDesc = styled(Descriptions)`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 12px;
  }
`;
type AutoCompleteLabelProps = {
  contact: Contact;
  activeKey?: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const AutoCompleteLabel: FC<AutoCompleteLabelProps> = ({
  contact,
  activeKey,
  setActiveKey,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (panelRef.current !== null) {
      // @ts-ignore
      panelRef.current
        .querySelector('.ant-collapse-arrow')
        .addEventListener('click', (e) => {
          e.stopPropagation();
          setActiveKey(contact.id !== activeKey ? contact.id : undefined);
        });
    }
  }, [panelRef, contact.id, activeKey, setActiveKey]);
  return (
    <div ref={panelRef}>
      <Collapse ghost activeKey={activeKey} expandIconPosition="right">
        <Panel
          key={contact.id as string}
          forceRender
          header={
            <Row align="middle">
              <Col flex="36px">
                <Avatar src={contact.avatar} size={24} />
              </Col>
              <Col>
                {contact.type === 'person'
                  ? `${contact.firstName} ${contact.lastName}`
                  : contact.companyName}
              </Col>
            </Row>
          }
        >
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col flex="36px" />
            <Col>
              <AutoCompleteDesc column={1} size="small">
                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="documents.autocompletecontact.address.label"
                      defaultMessage="Address"
                    />
                  }
                >
                  {(contact.addresses as AddressEntry[])?.[0]?.street}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="documents.autocompletecontact.telephone.label"
                      defaultMessage="Bank account"
                    />
                  }
                >
                  {contact.bankAccounts?.[0]?.iban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="documentprocessing.step2.url.label"
                      defaultMessage="Telephone"
                    />
                  }
                >
                  {contact.phones?.[0]?.number || '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="documents.autocompletecontact.email.label"
                      defaultMessage="E-mail"
                    />
                  }
                >
                  {(contact.emails as Email[])?.[0]?.email}
                </Descriptions.Item>
              </AutoCompleteDesc>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};
const StyledAutoCompleteButton = styled('div')`
  padding: 8px 12px;
`;
type AutoCompleteButtonProps = {
  children: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
const AutoCompleteButton: FC<AutoCompleteButtonProps> = ({
  children,
  onClick,
}) => (
  <StyledAutoCompleteButton onClick={onClick}>
    <Row align="middle">
      <Col flex="36px">
        <PlusOutlined style={{ fontSize: 14 }} />
      </Col>
      <Col>{children}</Col>
    </Row>
  </StyledAutoCompleteButton>
);

type AutoCompleteContactProps = {
  disabled: boolean;
  onSelect: (contact: Contact, existed: boolean) => void;
  ecosystem?: string;
};

const AutoCompleteContact: FC<AutoCompleteContactProps> = ({
  disabled = false,
  onSelect,
  ecosystem,
}) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const autocompleteRef = useRef(null);
  const intl = useIntl();
  useEffect(() => {
    getContacts({
      fields: [
        'id',
        'type',
        'companyName',
        'ecosystem',
        'firstName',
        'lastName',
        'avatar',
        'addresses',
        'bankAccounts',
        'phoneNumbers',
        'emails',
      ],
      filters: {
        ecosystem: {
          filter: ecosystem,
          type: 'contains',
          filterType: 'text',
        } as FilterTextObject,
      },
    }).then((res: ApiResponse) => {
      setContacts(res.results);
    });
  }, [ecosystem]);
  const searchLowerCase = search.toLowerCase();
  const options = contacts
    .filter(
      (c) =>
        c.companyName?.toLowerCase().includes(searchLowerCase) ||
        c.firstName?.toLowerCase().includes(searchLowerCase) ||
        c.lastName?.toLowerCase().includes(searchLowerCase),
    )
    // limit results to 20
    .slice(0, 20)
    .map((c) => ({
      label: (
        <AutoCompleteLabel
          contact={c}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      ),
      value: c.id,
    }));

  if (search) {
    options.push({
      label: (
        <AutoCompleteButton
          onClick={(e) => {
            e.stopPropagation();
            onSelect(
              {
                type: 'person',
                firstName: search,
                ecosystem,
              },
              false,
            );
            // @ts-ignore
            autocompleteRef?.current?.blur();
          }}
        >
          {intl.formatMessage({
            id: 'documents.autocompletecontact.autocompletebutton.person',
            defaultMessage: 'Create a new person contact',
          })}
        </AutoCompleteButton>
      ),
      value: 'person',
    });
    options.push({
      label: (
        <AutoCompleteButton
          onClick={(e) => {
            e.stopPropagation();
            onSelect(
              {
                type: 'company',
                companyName: search,
                ecosystem,
              },
              false,
            );
            // @ts-ignore
            autocompleteRef?.current?.blur();
          }}
        >
          {intl.formatMessage({
            id: 'documents.autocompletecontact.autocompletebutton.company',
            defaultMessage: 'Create a new company contact',
          })}
        </AutoCompleteButton>
      ),
      value: 'company',
    });
  }
  return (
    <AutoComplete
      // clear input if it is disabled
      value={disabled ? '' : value}
      disabled={disabled}
      dropdownClassName="contacts-autocomplete-dropdown"
      // @ts-ignore
      options={options}
      ref={autocompleteRef}
      onSearch={setSearch}
      onSelect={(contactId) => {
        onSelect(
          {
            id: contactId as string,
          },
          true,
        );
        setValue('');
      }}
      onChange={setValue}
      style={{ width: '100%' }}
    >
      <Input
        prefix={<SearchOutlined />}
        placeholder={intl.formatMessage({
          id: 'documents.autocompletecontact.placeholder',
          defaultMessage: 'Search in Contacts',
        })}
      />
    </AutoComplete>
  );
};

export default AutoCompleteContact;
