import { firebase } from '@services/Firebase';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { MAIL_COLLECTION, MailEntry } from '@services/email/namespace';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';

const getCommissionMailsForConsultant = (
  ecosystemId: string,
  consultantId: string,
) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve(null);
  }

  return firebase.firestore
    ?.collection(MAIL_COLLECTION)
    .where('ecosystemId', '==', ecosystemId)
    .where('consultantId', '==', consultantId)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as MailEntry[]) : [];
    });
};

export default getCommissionMailsForConsultant;
