import { createIntl, createIntlCache } from 'react-intl';
import dayjs from 'dayjs';

//TODO: remove this after fixing dynamic import in injectAppsLocalizations
import weekPlanEn from '@apps/weekplan/i18n/en.json';
import weekPlanDe from '@apps/weekplan/i18n/de.json';
import projectsEn from '@apps/projects/i18n/en.json';
import projectsDe from '@apps/projects/i18n/de.json';
import tasksEn from '@apps/tasks/i18n/en.json';
import tasksDe from '@apps/tasks/i18n/de.json';
import documentsEn from '@apps/documents/i18n/en.json';
import documentsDe from '@apps/documents/i18n/de.json';
import contactsEn from '@apps/contacts/i18n/en.json';
import contactsDe from '@apps/contacts/i18n/de.json';
import salesEn from '@apps/sales/i18n/en.json';
import salesDe from '@apps/sales/i18n/de.json';
import productsEn from '@apps/products/i18n/en.json';
import productsDe from '@apps/products/i18n/de.json';
import warehouseEn from '@apps/warehouse/i18n/en.json';
import warehouseDe from '@apps/warehouse/i18n/de.json';
import directSalesEn from '@apps/directSales/i18n/en.json';
import directSalesDe from '@apps/directSales/i18n/de.json';
import directSalesClientEn from '@apps/directSalesClient/i18n/en.json';
import directSalesClientDe from '@apps/directSalesClient/i18n/de.json';
import financeEn from '@apps/finance/i18n/en.json';
import financeDe from '@apps/finance/i18n/de.json';

let messagesEn = require('@assets/i18n/en.json');
let messagesDe = require('@assets/i18n/de.json');

export const availableLocales = ['en', 'de'];

const cache = createIntlCache();
let locale = 'en';
export let messages = {
  ...messagesEn,
  ...weekPlanEn,
  ...projectsEn,
  ...tasksEn,
  ...documentsEn,
  ...contactsEn,
  ...salesEn,
  ...productsEn,
  ...warehouseEn,
  ...directSalesEn,
  ...directSalesClientEn,
  ...financeEn,
};

if (navigator.language.includes('de')) {
  require('dayjs/locale/de.js');
  dayjs().locale('de');
  locale = 'de';
  messages = {
    ...messagesDe,
    ...weekPlanDe,
    ...projectsDe,
    ...tasksDe,
    ...documentsDe,
    ...contactsDe,
    ...salesDe,
    ...productsDe,
    ...warehouseDe,
    ...directSalesDe,
    ...directSalesClientDe,
    ...financeDe,
  };
}

const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

import(/* webpackChunkName: "moment" */ 'moment').then(
  ({ default: moment }) => {
    moment.locale(locale);
    moment.defaultFormat = 'DD.MM.YYYY';
  },
);

export const formatMessage = (id: string) => intl.formatMessage({ id });

export default intl;
