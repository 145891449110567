import { database } from 'firebase';
import { RootStore, rootStore } from '@core/store';
import { COLLECTION_NAME } from '@services/permissions/index';
import { InitPermissionAction } from '@core/store/permissions';
import { FileApp } from '@apps/AppTypes';

const addPermission = (data: database.DataSnapshot, userId: string) => {
  console.log(data, userId);
};

const updatePermission = (data: database.DataSnapshot, userId: string) => {
  console.log(data, userId);
};

const deletePermission = (data: database.DataSnapshot, userId: string) => {
  console.log(data, userId);
};

const permissionsListener = (userId: string, db?: database.Database) => {
  const store = rootStore.getState() as RootStore;
  const { ecosystemRelations, licences } = store;
  const licencesEntries = Object.values(licences.data);

  ecosystemRelations.data.forEach((ecosystemRelation) => {
    if (ecosystemRelation.role === 'owner') {
      const ecoId = ecosystemRelation.ecosystem;
      const ecoLicences = licencesEntries.filter(
        (el) => el.ecosystem === ecoId,
      );
      const data = ecoLicences.reduce((acc, el) => {
        const appConfig = ((window as any).connectedApps as FileApp[]).find(
          (connectedApp) => {
            return connectedApp.todixId === el.todixId;
          },
        );
        if (appConfig && el.todixId) {
          return {
            ...acc,
            [el.todixId]: appConfig.actions?.reduce((ac, item) => {
              return {
                ...ac,
                [item]: 'all',
              };
            }, {}),
          };
        }
        return acc;
      }, {});
      rootStore.dispatch({
        type: 'INIT_PERMISSION',
        payload: {
          ecosystemId: ecoId,
          permissions: data,
        },
      } as InitPermissionAction);
    }
  });
  delete (window as any).connectedApps;

  const ref = db?.ref(`${COLLECTION_NAME}/${userId}`);
  ref?.on('child_added', (data: any) => {
    addPermission(data, userId);
  });
  ref?.on('child_changed', (data: any) => {
    updatePermission(data, userId);
  });
  ref?.on('child_removed', (data: any) => {
    deletePermission(data, userId);
  });
};

export default permissionsListener;
