import React, { FC, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const WeekPlan = lazy(
  () => import(/* webpackChunkName: "weekPlanApp" */ './WeekPlan'),
);

const WeekPlans = lazy(
  () => import(/* webpackChunkName: "weekPlanApp" */ './WeekPlans'),
);

const Management: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <WeekPlan />
      </Route>
      <Route path={`${path}/:id`}>
        <WeekPlan />
      </Route>
      <Route path={`${path}`}>
        <WeekPlans />
      </Route>
    </Switch>
  );
};

export default Management;
