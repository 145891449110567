import React, { FC, useState, useEffect, CSSProperties } from 'react';
import { Card, Space, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import stepTypeIconMapper from '@apps/sales/mainMenu/stepTypeIconMapper';
import { UseParamsType } from '@pages/settings';
import { getDocumentsTypesSettings } from '@apps/documents/service';
import StepSettings from '@apps/sales/settings/OperationSteps/StepSettings';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { fetchUser } from '@services/users';
import { TabIconContainer } from '@apps/sales/settings/OperationSteps/style.sc';

const stepTypes = Object.keys(stepTypeIconMapper);

const { TabPane } = Tabs;

const OperationSteps: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const ecosystemData = useSelector(getEcosystemById(uid || '')) as Ecosystem;
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<string[]>([]);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (ecosystemData) {
      fetchUser(ecosystemData.ownerUid).then((response) => {
        if (response) {
          setUser(response);
        }
      });
    }
  }, [ecosystemData]);

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getDocumentsTypesSettings(uid)
        ?.then((results) => {
          if (results) {
            const documentTypes = results.docTypes
              .filter((entry) => entry.isActive)
              .map((entry) => entry.docType)
              .concat(['performaInvoice']);
            const filteredStepTypes = stepTypes.filter((type) =>
              documentTypes.includes(type),
            );
            setSteps(filteredStepTypes);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [uid]);
  return (
    <Card
      title={
        <FormattedMessage
          id="sales.operation.steps.settings"
          defaultMessage="Operation steps"
        />
      }
      loading={loading}
    >
      <Tabs>
        {steps.map((step, key) => {
          const Icon: FC<{ style: CSSProperties }> = stepTypeIconMapper[step];
          return (
            <TabPane
              tab={
                <Space>
                  <TabIconContainer>
                    <Icon style={{ color: '#fff' }} />
                  </TabIconContainer>
                  <div>
                    <FormattedMessage
                      id={`documents.documentTypes.${step}`}
                      defaultMessage={step}
                    />
                  </div>
                </Space>
              }
              key={key}
            >
              <StepSettings
                step={step}
                ecosystemId={uid}
                user={user}
                ecosystem={ecosystemData}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </Card>
  );
};

export default OperationSteps;
