import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { getApplications } from '@core/services';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { EcosystemState } from '@components/EcosystemIndicator/store';
import { DashboardState } from '@core/layout/namespace';
import { UserState } from '@core/store/user';
import { ProductGroupState } from '@core/store/productGroups/reducers';
import { UnitGroups } from '@services/unitGroups/namespace';
import { LicencesState } from '@core/store/licences';
import { CartState } from '@core/store/cart/reducer';
import { EcosystemRelationsState } from '@core/store/ecosystemRelations/reducer';
import { StripeProductsState } from '@core/store/stripeProducts/reducers';
import { UserPreferencesState } from '@core/store/userPreferences/reducer';

export interface RootStore {
  user: UserState;
  userPreferences: UserPreferencesState;
  dashboard: DashboardState;
  ecosystems: EcosystemState;
  productGroups: ProductGroupState;
  unitGroups: UnitGroups[];
  licences: LicencesState;
  cart: CartState;
  ecosystemRelations: EcosystemRelationsState;
  stripeProducts: StripeProductsState;
}

const dependencies = {
  getApplications,
};
export type Dependencies = typeof dependencies;

const usedMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [usedMiddleware, logger];

export const rootStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

usedMiddleware.run(rootSaga);
