import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Button } from 'antd';
import moreIcon from './assets/more.svg';
import Modal from '@components/Modal';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { IntlShape, useIntl } from 'react-intl';
import { ActionOption } from '@components/AppView/index';

export type ExtraOptions = MenuItemProps[];

export type ActionRendererProps = {
  path: string;
  options: ActionOption[];
  extraOptions?: ExtraOptions;
  handleDelete: () => void;
  intl: IntlShape;
};

const getOverlay = ({
  path,
  options,
  extraOptions = [],
  handleDelete,
  intl,
}: ActionRendererProps) => {
  const optionsEntries: Record<string, string> = options.reduce((acc, it) => {
    return {
      ...acc,
      [it.action]: it.label,
    };
  }, {});
  return (
    <Menu>
      {optionsEntries.open && (
        <Menu.Item>
          <Link to={`${path}`}>{optionsEntries.open}</Link>
        </Menu.Item>
      )}
      {optionsEntries.edit && (
        <Menu.Item>
          <Link to={`${path}/edit`}>{optionsEntries.edit}</Link>
        </Menu.Item>
      )}
      {extraOptions.map((itemProps: MenuItemProps, index) => (
        <Menu.Item key={index} {...itemProps} />
      ))}
      {optionsEntries.delete && (
        <Menu.Item
          danger
          onClick={() => {
            Modal.confirm({
              content: intl.formatMessage({
                id: 'appview.delete.confirm',
                defaultMessage: 'Are you sure you want to remove this item?',
              }),
              onOk: handleDelete,
            });
          }}
        >
          {optionsEntries.delete}
        </Menu.Item>
      )}
    </Menu>
  );
};

export const ActionRenderer = ({
  path,
  options,
  extraOptions,
  handleDelete,
}: ActionRendererProps) => {
  const intl = useIntl();
  return (
    <Dropdown
      overlay={getOverlay({
        path,
        options,
        extraOptions,
        handleDelete,
        intl,
      })}
    >
      <Button
        type="text"
        size="small"
        icon={<img src={moreIcon} alt="more" />}
      />
    </Dropdown>
  );
};
