import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import {
  HistoryTotalEntry,
  WarehouseHistoryEntry,
  WarehouseStorage,
  WarehouseStorageEntry,
} from '@apps/warehouse/services/namespace';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { Spin } from 'antd';
import { styled } from '@styles/themes';
import StorageSelect from '@apps/warehouse/widgets/WarehouseForm/StorageSelect';
import fetchWarehouseStoragesForEcosystem from '@apps/warehouse/services/fetchWarehouseStoragesForEcosystem';
import Location from '@apps/warehouse/widgets/WarehouseForm/Location';
import StockLevelInput from '@apps/warehouse/widgets/WarehouseForm/StockLevelInput';
import { useIntl } from 'react-intl';
import createWarehouseStorageEntry from '@apps/warehouse/services/createWarehouseStorageEntry';
import { FormInstance } from 'antd/lib/form';
import updateWarehouseStorageEntry from '@apps/warehouse/services/updateWarehouseStorageEntry';
import ProductPlot from '@apps/warehouse/mainMenu/components/ProductPlot';
import fetchFilteredHistoryForStorage from '@apps/warehouse/services/fetchFilteredHistoryForStorage';
import HistoryTable from '@apps/warehouse/mainMenu/components/HistoryTable';
import ProductStats from '@apps/warehouse/widgets/WarehouseForm/ProductStats';
import fetchProductStatsHistory from '@apps/warehouse/services/fetchProductStatsHistory';

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 20px;

  && {
    .ant-input-number {
      width: 100%;
    }
    .ant-form-item[data-level='max'] {
      .ant-input-number {
        border-color: #531dab;
      }
    }
    .ant-form-item[data-level='target'] {
      .ant-input-number {
        border-color: #0e7d40;
      }
    }
    .ant-form-item[data-level='order'] {
      .ant-input-number {
        border-color: #ffa100;
      }
    }
    .ant-form-item[data-level='critical'] {
      .ant-input-number {
        border-color: #cf1322;
      }
    }
  }
`;

type WarehouseFormProps = {
  ecosystemId: string;
  productId?: string;
  integratedAppId: string;
  readOnly?: boolean;
};

const WarehouseForm: FC<WarehouseFormProps> = ({
  productId,
  integratedAppId,
  readOnly,
  ecosystemId,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState<WarehouseHistoryEntry[]>([]);
  const [history, setHistory] = useState<HistoryTotalEntry[]>([]);
  const [storages, setStorages] = useState<WarehouseStorage[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<
    WarehouseStorageEntry | undefined
  >(undefined);
  useEffect(() => {
    if (ecosystemId) {
      fetchWarehouseStoragesForEcosystem(ecosystemId)?.then(setStorages);
    }
  }, [ecosystemId]);

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const setStorage = useCallback(
    (storageId, form: FormInstance) => {
      const storage = storages.find((storage) => storage.id === storageId);

      if (storage && productId) {
        const entries = storage.entries
          ? Object.entries(storage.entries).map((parts) => ({
              ...parts[1],
              id: parts[0],
            }))
          : [];

        const warehouseEntry = entries.find(
          (entity) => entity.productId === productId,
        );

        setSelectedEntry(warehouseEntry);
        form.setFieldsValue({
          storageId,
          productId,
          location: warehouseEntry?.location || '',
          criticalLevel: warehouseEntry?.criticalLevel,
          maximumLevel: warehouseEntry?.maximumLevel,
          orderLevel: warehouseEntry?.orderLevel,
          targetLevel: warehouseEntry?.targetLevel,
        });
        setIsLoading(true);
        fetchProductStatsHistory({
          ecosystem: ecosystemId,
          storageId,
          productId,
        })
          ?.then(setHistory)
          .finally(() => {
            setIsLoading(false);
          });
        fetchFilteredHistoryForStorage({
          ecosystem: ecosystemId,
          storageId,
          filter: {
            field: 'productId',
            value: productId,
          },
        })
          ?.then(setEntries)
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [ecosystemId, productId, storages],
  );

  const handleSubmit = useCallback(
    (entry: WarehouseStorageEntry) => {
      if (productId && ecosystemId) {
        const entryToSave = {
          ...entry,
          productId,
          id: selectedEntry?.id || '',
        };
        setIsLoading(true);
        if (entryToSave.id) {
          setIsLoading(false);
          updateWarehouseStorageEntry(ecosystemId, entryToSave)
            ?.then(() => {
              fetchWarehouseStoragesForEcosystem(ecosystemId)?.then(
                setStorages,
              );
            })
            ?.finally(() => {
              setIsLoading(false);
            });
        } else {
          createWarehouseStorageEntry(ecosystemId, entryToSave)
            ?.then(() => {
              fetchWarehouseStoragesForEcosystem(ecosystemId)?.then(
                setStorages,
              );
            })
            ?.finally(() => {
              setIsLoading(false);
            });
        }
      }
    },
    [ecosystemId, productId, selectedEntry?.id],
  );

  return (
    <Spin spinning={isLoading}>
      <div>
        <AppEntityForm
          elId={productId}
          name="warehouseTab"
          appId={integratedAppId}
          initialValues={initialValues}
          readOnly={readOnly}
          onSubmit={handleSubmit}
        >
          {(form) => {
            const storageOptions = storages.map((storage) => ({
              value: storage.id as string,
              label: storage.name,
            }));
            const storageId = form.getFieldValue('storageId');
            return (
              <FormContainer>
                <div>
                  <StorageSelect
                    readOnly={false}
                    options={storageOptions}
                    onChange={(id) => setStorage(id, form)}
                  />
                  <Location readOnly={readOnly || false} />
                  <StockLevelInput
                    name="maximumLevel"
                    label={intl.formatMessage({
                      id: 'warehousetab.stock.level.maximum',
                      defaultMessage: 'Maximum',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'warehousetab.stock.level.placeholder',
                      defaultMessage: 'Put level...',
                    })}
                    level="max"
                    readOnly={readOnly || false}
                  />
                  <StockLevelInput
                    name="targetLevel"
                    label={intl.formatMessage({
                      id: 'warehousetab.stock.level.target',
                      defaultMessage: 'Target',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'warehousetab.stock.level.placeholder',
                      defaultMessage: 'Put level...',
                    })}
                    level="target"
                    readOnly={readOnly || false}
                  />
                  <StockLevelInput
                    name="orderLevel"
                    label={intl.formatMessage({
                      id: 'warehousetab.stock.level.order',
                      defaultMessage: 'Order',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'warehousetab.stock.level.placeholder',
                      defaultMessage: 'Put level...',
                    })}
                    level="order"
                    readOnly={readOnly || false}
                  />
                  <StockLevelInput
                    name="criticalLevel"
                    label={intl.formatMessage({
                      id: 'warehousetab.stock.level.critical',
                      defaultMessage: 'Critical',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'warehousetab.stock.level.placeholder',
                      defaultMessage: 'Put level...',
                    })}
                    level="critical"
                    readOnly={readOnly || false}
                  />
                </div>
                {storageId && ecosystemId && productId && (
                  <ProductStats
                    storageId={storageId}
                    ecosystem={ecosystemId}
                    productId={productId}
                  />
                )}
              </FormContainer>
            );
          }}
        </AppEntityForm>
      </div>
      <div>
        <ProductPlot entries={history} />
      </div>
      <div>
        <HistoryTable data={entries} />
      </div>
    </Spin>
  );
};

export default WarehouseForm;
