import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@core/components/AppView';
import Create from '@apps/contacts/mainMenu/Create';
import Edit from '@apps/contacts/mainMenu/Edit';
import Preview from '@apps/contacts/mainMenu/Preview';
import { deleteContact } from '@apps/contacts/services';
import { columns } from './columns';
import { getRows } from './getRows';
import { SideContent } from './SideContent';
import optionsMapper from '@core/helpers/optionsMapper';

const Contacts: React.FC = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:id`} component={Preview} />
      <Route exact path={`${path}/:id/edit`} component={Edit} />
      <Route path={`${path}`}>
        <AppView
          breadcrumbItems={[
            [
              path,
              intl.formatMessage({
                id: 'contacts.nav.contacts',
                defaultMessage: 'Contacts',
              }),
            ],
          ]}
          columns={columns(intl)}
          getRows={getRows}
          sideContent={SideContent}
          path={path}
          onCellClickPath={path}
          tabs={['list']}
          onRemoveItem={deleteContact}
          hideQuickFilter
          options={optionsMapper(['edit', 'delete'], intl)}
        />
      </Route>
    </Switch>
  );
};

export default Contacts;
