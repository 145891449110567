import React from 'react';
import { Card, Typography } from 'antd';
import { styled } from '@styles/themes';
import StatisticsWidget from '@pages/dashboard/StatisticsWidget';
import DashboardWidgets from '@pages/dashboard/DashboardWidgets';

const { Title, Link } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InsideGrid = styled.div`
  display: grid;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
`;

export const StyledDashboardCard = styled(Card)`
  display: flex;
  background: transparent;
  flex-direction: column;
  height: 180px;

  > .ant-card-body {
    padding: 12px;
    height: 100%;
  }
`;

const Dashboard = () => {
  return (
    <Container>
      <InsideGrid>
        <DashboardWidgets />
      </InsideGrid>
      <InsideGrid>
        <div>
          Melde dich{' '}
          <Link href="http://www.todix.de" target="_blank">
            hier
          </Link>{' '}
          zu unserem Newsletter an um stets über die neuesten Funktionen und
          Apps informiert zu sein.
          <Title level={4}>Todix Support</Title>
          <div>
            <p>
              <a href="mailto:support@todix.de">Support via Mail!</a>
            </p>
          </div>
        </div>
        <StatisticsWidget />
      </InsideGrid>
    </Container>
  );
};

export default Dashboard;
