import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Form, Avatar } from 'antd';

import { RootStore } from '@core/store';
import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { FormattedMessage } from 'react-intl';
import appConfig from '@apps/products';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = (state: RootStore) => ({
  ecosystems: getActiveEcosystemsForApp(state, appConfig.todixId),
});

export type StateProps = ReturnType<typeof mapStateToProps>;

export const SelectedEcosystem: FC<StateProps> = ({ ecosystems }) => {
  const { readOnly } = useFormContext();
  return (
    <Item
      name="ecosystem"
      label={
        <FormattedMessage
          id="products.selectedecosystem.label"
          defaultMessage="Ecosystem"
        />
      }
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="products.selectedecosystem.error"
              defaultMessage="Missing ecosystem"
            />
          ),
        },
      ]}
    >
      <Select
        name="ecosystem"
        placeholder={
          <FormattedMessage
            id="products.selectedecosystem.placeholder"
            defaultMessage="Ecosystem"
          />
        }
        aria-required="true"
        disabled={readOnly}
      >
        {ecosystems.map(({ id, name, avatarUrl }) => (
          <Option key={id} value={id}>
            <Avatar src={avatarUrl} size="small" /> {name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default connect(mapStateToProps)(SelectedEcosystem);
