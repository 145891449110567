import { IGetRowsParams } from 'ag-grid-community';
import { getDocuments, Document } from '@apps/documents/service';
import { FilterTextObject } from '@services/filtering';
import moment from 'moment';

const getRows = (
  params: IGetRowsParams & {
    ecosystems?: string[];
    query?: string;
    docsToProcessing?: Partial<Document>[];
  },
) => {
  const filtersEntries = Object.entries(params.filterModel).map(
    ([key, filterObj]) => [
      key,
      // @ts-ignore
      filterObj,
    ],
  );
  if (!params.filterModel.ecosystem && params.ecosystems) {
    filtersEntries.push([
      'ecosystem',
      {
        filter: params.ecosystems.join('|'),
        filterType: 'text',
        type: 'contains',
      } as FilterTextObject,
    ]);
  }

  if (params.docsToProcessing) {
    const formattedResponse = params.docsToProcessing.map((el) => {
      if (el.creationDate) {
        el.creationDate = moment(el.creationDate).format(moment.defaultFormat);
      }
    });

    params.successCallback(formattedResponse, formattedResponse.length);
    return;
  }
  getDocuments({
    query: params.query,
    offset: params.startRow,
    limit: params.endRow - params.startRow,
    fields: [
      'id',
      'receivingDate',
      'ecosystem',
      'type',
      'category',
      'tags',
      'creationDate',
    ],
    filters: Object.fromEntries(filtersEntries),
    sort: params.sortModel.map(
      (model: { colId: string; sort: string }) =>
        `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
    ),
  }).then((response) => {
    const formattedResponse = response.results.map((el) => {
      if (el.creationDate) {
        el.creationDate = moment(el.creationDate).format(moment.defaultFormat);
      }
    });

    params.successCallback(formattedResponse, formattedResponse.length);
  });
};

export default getRows;
