import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { firebase } from '@services/Firebase';
import { Form } from 'antd';
import AppConfig from '@apps/warehouse';
import FormContainer from '@components/FormContainer';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SelectOptionFlow from '@apps/warehouse/mainMenu/components/FormComponents/SelectOptionFlow';
import SelectedEcosystem from '@components/SelectedEcosystem';
import DateEntry from '@apps/warehouse/mainMenu/components/FormComponents/DateEntry';
import Order from '@apps/warehouse/mainMenu/components/FormComponents/Order';
import NumberDeliveryNote from '@apps/warehouse/mainMenu/components/FormComponents/NumberDeliveryNote';
import Supplier from '@apps/warehouse/mainMenu/components/FormComponents/Supplier';
import User from '@apps/warehouse/mainMenu/components/FormComponents/User';
import Reason from '@apps/warehouse/mainMenu/components/FormComponents/Reason';
import { FlowOption, ProductEntry } from '@apps/warehouse/services/namespace';

type FlowManagementFormProps = {
  entries: ProductEntry[];
  setEntries(entry: ProductEntry[]): void;
  onSubmit(values: any): void;
  onEcosystemSelect(id: string): void;
  ecosystemId?: string;
};

const FlowManagementForm: FC<FlowManagementFormProps> = ({
  onSubmit,
  onEcosystemSelect,
  entries,
  setEntries,
  ecosystemId,
}) => {
  const user = firebase.auth.currentUser;
  const [flow, setFlow] = useState<FlowOption | null>(null);
  const [form] = useForm();
  const onOptionChange = useCallback(
    (option) => {
      setFlow(option as FlowOption);
      form.setFieldsValue({
        supplier: '',
        orderId: '',
      });
    },
    [form],
  );
  const onEcosystemChange = useCallback(
    (id: string) => {
      form.setFieldsValue({
        supplier: '',
        orderId: '',
      });
      setEntries([]);
      onEcosystemSelect(id);
    },
    [setEntries, form, onEcosystemSelect],
  );
  const handleSubmitWithEntries = useCallback(
    (values) => {
      if (entries.length) {
        onSubmit({
          ...values,
          entries,
          flow,
        });
      }
    },
    [entries, flow, onSubmit],
  );
  return (
    <AppEntityForm
      appId={AppConfig.todixId}
      initialValues={{
        timestamp: moment(),
        user: user?.uid,
        ecosystem: ecosystemId,
      }}
      providedForm={form}
      onSubmit={handleSubmitWithEntries}
      disabledSubmitButton={
        flow === null || !form.getFieldValue('ecosystem') || entries.length < 1
      }
      readOnly={false}
    >
      {() => {
        return (
          <>
            <FormContainer>
              <div>
                <SelectOptionFlow onOptionChange={onOptionChange} />
                <DateEntry flow={flow} />
                {flow === 'incoming' && <NumberDeliveryNote />}
                {flow === 'incoming' && (
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, curr) =>
                      prev.ecosystem !== curr.ecosystem
                    }
                  >
                    {() => <Supplier />}
                  </Form.Item>
                )}
                {flow !== 'incoming' && <Reason />}
              </div>
              <div>
                <SelectedEcosystem
                  appId={AppConfig.todixId}
                  onChange={(id: string) => {
                    if (onEcosystemChange) {
                      onEcosystemChange(id);
                    }
                  }}
                />
                <Form.Item noStyle shouldUpdate={true}>
                  {() => <User />}
                </Form.Item>
                {flow === 'incoming' && <Order />}
              </div>
            </FormContainer>
          </>
        );
      }}
    </AppEntityForm>
  );
};

export default FlowManagementForm;
