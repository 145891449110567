import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

import { Card } from '@todix/ui-components';

import Tabs from '@components/Tabs';

import AppConfig from '@apps/sales';

import { FormattedMessage, useIntl } from 'react-intl';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import { TabsWithOverflowHidden } from '@apps/sales/mainMenu/styles.sc';
import SaleCreator from '@apps/sales/mainMenu/components/SaleCreator';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';
const { TabPane } = Tabs;

const Create: FC<{}> = () => {
  const { id } = useParams<{ id: string; appId: string }>();
  const intl = useIntl();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(AppConfig.todixId);

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}`,
        intl.formatMessage({
          id: 'sales.detailedsale.nav.sales',
          defaultMessage: 'Sales',
        }),
      ],
      id
        ? [`/app/${AppConfig.todixId}/${id}`, id]
        : [
            `/app/${AppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'sales.detailedsale.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];

    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [id, intl]);

  return (
    <>
      <Card>
        <TabsWithOverflowHidden defaultActiveKey="1" onChange={() => {}}>
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="tasks.detailedtask.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <SaleCreator saleId={id} ecosystemId={firstActiveEcosystemId} />
          </TabPane>
        </TabsWithOverflowHidden>
      </Card>
    </>
  );
};

export default Create;
