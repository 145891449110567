import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import {
  ItemNoMargin,
  ShortInput,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';

const { Item } = Form;

type Props = InputProps & {
  name: string;
};

const calculateValues = (
  discountValue: string,
  formValues: Record<string, any>,
) => {
  const { price, taxValue: tax, amount } = formValues;
  const discount =
    discountValue !== undefined && discountValue !== ''
      ? Number(discountValue)
      : 0;
  const taxValue = tax !== undefined && tax !== '' ? Number(tax) : 0;
  let values: any = {
    discount,
    taxValue,
  };
  if (price !== '' && amount) {
    const discountedPrice = Number(
      Number(price - (Number(discount) / 100) * price).toFixed(2),
    );
    const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
    const grossPrice = Number(
      Number(discountedPrice + discountedPrice * (tax / 100)).toFixed(2),
    );
    const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

    values = {
      ...values,
      grossPrice,
      discountedPrice,
      totalNetPrice,
      totalGrossPrice,
    };
  }
  return values;
};

const Discount: FC<Props> = ({ name, ...inputProps }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <ShortInput
              {...inputProps}
              maxLength={4}
              onChange={(event) => {
                const value = event.target.value;
                const newValues = calculateValues(value, formValues);
                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default Discount;
