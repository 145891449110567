import React, { FC, useState, useCallback, useEffect } from 'react';
import { Spin, message } from 'antd';
import AppConfig from '@apps/warehouse';
import { useHistory } from 'react-router-dom';
import { Card } from '@todix/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from '@components/Tabs';
import { DashboardOutlined } from '@ant-design/icons';
import FlowManagement from '@apps/warehouse/mainMenu/components/FlowManagement';
import moment from 'moment';
import { WarehouseFlow } from '@apps/warehouse/services/namespace';
import handleWarehouseFlow from '@apps/warehouse/services/handleWarehouseFlow';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

const { TabPane } = Tabs;

const Create: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(AppConfig.todixId);

  const handleSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      const entryToSave: WarehouseFlow = {
        ...values,
        timestamp: (values.timestamp as typeof moment).utc().unix(),
      };

      handleWarehouseFlow(entryToSave)
        ?.then(() => {
          message
            .success(
              intl.formatMessage({
                id: 'warehouse.flow.success.message',
                defaultMessage:
                  'Warehouse operation has been added for processing',
              }),
            )
            .then(() => {
              setIsSubmitting(false);
              history.push(`/app/${AppConfig.todixId}`);
            });
        })
        .catch(() => {
          setIsSubmitting(false);
          message.error(
            intl.formatMessage({
              id: 'warehouse.flow.error.message',
              defaultMessage: 'Error while adding warehouse operation',
            }),
          );
        });
    },
    [history, intl],
  );

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}`,
        intl.formatMessage({
          id: 'warehouse.nav.warehouses',
          defaultMessage: 'Warehouses',
        }),
      ],
      [
        `/app/${AppConfig.todixId}/create`,
        intl.formatMessage({
          id: 'warehouse.nav.create',
          defaultMessage: 'Create',
        }),
      ],
    ];

    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [intl]);

  return (
    <Spin spinning={isSubmitting}>
      <Card>
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="warehouse.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <FlowManagement
              onSubmit={handleSubmit}
              ecosystemId={firstActiveEcosystemId}
            />
          </TabPane>
        </Tabs>
      </Card>
    </Spin>
  );
};

export default Create;
