import get from 'lodash.get';
import set from 'lodash.set';
import {
  post,
  get as apiGet,
  getWithId,
  deleteRequest,
  put,
} from '@core/services/api';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { Contact } from '@apps/contacts/services';
import { getFilteredData } from '@services/filtering';
import moment from 'moment';
import appConfig from '@apps/contacts';
import { firebase } from '@services/Firebase';

export * from './namespace';

type GetContactsParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

export type ApiResponse<T = object> = {
  results: T[];
  info: {
    results: number;
  };
};

export type GetContacts = (params: GetContactsParams) => Promise<ApiResponse>;

const COLLECTION_NAME = 'contacts';

export const getContacts: GetContacts = (p) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 50,
    fields: ['id'],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(COLLECTION_NAME).then((data: Contact[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue?.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (desc) {
            return String(valueA).localeCompare(valueB);
          }
          return String(valueB).localeCompare(valueA);
        })
      : filtered;
    const choosenFields = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow;
    });
    const results = choosenFields.length;
    const page = choosenFields.slice(
      params.offset,
      params.offset + params.limit,
    );
    return {
      results: page,
      info: {
        results,
      },
    };
  });
};

export const getContactsForEcosystem = (ecosystem: string) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystem)) {
    return Promise.resolve([] as Contact[]);
  }
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs as Contact[];
    });
};

export const getContact = (id: string) =>
  getWithId(`${COLLECTION_NAME}/${id}`) as Promise<Contact>;

export const updateContact = (id: string, contact: object) =>
  put(`${COLLECTION_NAME}/${id}`, {
    ...contact,
    timestamp: moment().valueOf(),
  });

export const postContact = (contact: object) =>
  post(COLLECTION_NAME, { ...contact, timestamp: moment().valueOf() });

export const deleteContact = (id: string) =>
  deleteRequest(`${COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    return response;
  });
