import React, { FC, useCallback, useState } from 'react';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import { IntlShape } from 'react-intl';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import ProductWithPrices from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices';
import { DocumentType } from '@apps/documents/service';

type Props = {
  products: SalesFormValues['products'];
  intl: IntlShape;
  ecosystem: string;
  documentType: DocumentType;
};

const ProductsTable: FC<Props> = ({ products, ecosystem, documentType }) => {
  const { setFieldsValue, getFieldsValue, getFieldValue } = useFormContext();
  const [count, setCount] = useState(0);
  const setProducts = useCallback(
    (prodEntries) => {
      const oldValues = getFieldsValue();
      setFieldsValue({
        ...oldValues,
        products: [...prodEntries],
      } as SalesFormValues);
    },
    [getFieldsValue, setFieldsValue],
  );

  return (
    <ProductWithPrices
      entries={products}
      setEntries={setProducts}
      count={count}
      selectedEcosystem={ecosystem}
      setCount={setCount}
      saleId={getFieldValue('saleId')}
      documentType={documentType}
    />
  );
};

export default ProductsTable;
