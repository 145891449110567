import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import {
  ItemNoMargin,
  ShortInput,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { NumericFormat } from 'react-number-format';
const { Item } = Form;

type Props = InputProps & {
  name: string;
  handleSave(): void;
};

const calculateValues = (value: string, formValues: Record<string, any>) => {
  const { discountedPrice, amount } = formValues;
  const taxValue = value ? parseFloat(value) : 0;
  const grossPrice = Number(
    Number(discountedPrice + discountedPrice * (taxValue / 100)).toFixed(2),
  );
  const totalGrossPrice = amount * grossPrice;
  return {
    grossPrice,
    totalGrossPrice,
    taxValue,
  };
};

const TaxSelection: FC<Props> = ({
  name,
  onBlur,
  onPressEnter,
  handleSave,
}) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <NumericFormat
              allowNegative={false}
              max={100}
              decimalSeparator={','}
              decimalScale={2}
              thousandSeparator={'.'}
              onBlur={onBlur}
              onPressEnter={onPressEnter}
              customInput={ShortInput}
              onChange={(event) => {
                const value = event.target.value;
                const formatedValue = value
                  .replaceAll('.', '')
                  .replaceAll(',', '.');
                const newValues = calculateValues(formatedValue, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default TaxSelection;
