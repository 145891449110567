import { patch, post } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import { firebase } from '@services/Firebase';
import {
  OrderType,
  SALES_ORDER_TYPES_COLLECTION_NAME,
} from '@apps/sales/services/orderTypes/namespace';
import moment from 'moment/moment';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/sales';

export * from './namespace';

export const createSalesOrderType = (orderType: OrderType) =>
  post(`${SALES_ORDER_TYPES_COLLECTION_NAME}`, {
    ...removeUndefinedProperties(orderType),
    timestamp: moment().valueOf(),
  });

export const updateSalesOrderType = (id: string, orderType: OrderType) =>
  patch(`${SALES_ORDER_TYPES_COLLECTION_NAME}/${id}`, {
    ...removeUndefinedProperties(orderType),
    timestamp: moment().valueOf(),
  });

export const getActiveSalesOrderTypes = (
  ecosystemId: string,
): Promise<OrderType[]> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([] as OrderType[]);
  }

  return firebase.firestore
    ?.collection(SALES_ORDER_TYPES_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .where('isActive', '==', true)
    .get()
    .then(getArrayWithIds) as Promise<OrderType[]>;
};

export const getSalesOrderTypes = (
  ecosystemId: string,
): Promise<OrderType[]> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([] as OrderType[]);
  }

  return firebase.firestore
    ?.collection(SALES_ORDER_TYPES_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as OrderType[]) : [];
    });
};
