import React, { FC } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import FormContainer from '@components/FormContainer';
import SelectedEcosystem from '@components/SelectedEcosystem';
import AppConfig from '@apps/warehouse';
import StorageSelector from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/StorageSelector';
import { FormContext } from '@components/FormWithReminder/context';
import { WarehouseStorage } from '@apps/warehouse/services/namespace';

type ControlsProps = {
  onEcosystemSelect(ecosystemId: string): void;
  onStorageSelect(storage: WarehouseStorage): void;
  selectedEcosystem: string | null;
  isLoading: boolean;
  ecosystemId?: string;
};

const Controls: FC<ControlsProps> = ({
  onEcosystemSelect,
  onStorageSelect,
  selectedEcosystem,
  isLoading,
  children,
  ecosystemId,
}) => {
  const [form] = useForm();
  const intl = useIntl();
  return (
    <FormContext.Provider value={{ ...form }}>
      <Form form={form} initialValues={{ ecosystem: ecosystemId }}>
        <FormContainer>
          <SelectedEcosystem
            appId={AppConfig.todixId}
            onChange={onEcosystemSelect}
          />
          <Form.Item
            label={intl.formatMessage({
              id: 'warehouse.create.table.header.storage',
              defaultMessage: 'Storage',
            })}
            name="storage"
          >
            <StorageSelector
              selectedEcosystem={selectedEcosystem}
              onStorageSelect={onStorageSelect}
              disabled={isLoading}
            />
          </Form.Item>
          {children}
        </FormContainer>
      </Form>
    </FormContext.Provider>
  );
};

export default Controls;
