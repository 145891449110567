import React, { useContext } from 'react';
import { Form } from 'antd';
import {
  BigBold,
  FlexCenter,
  FlexCenterGray,
  GridTwoColumns,
  PricePreviewHeader,
  PriceSegmentCard,
} from '../styles.sc';
import intl from '../../../../../../intl';
import { ProductPrice } from '@apps/products/services';
import getValueWithCurrency from '@services/currency/getValueWithCurrency';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const { Item } = Form;

const PricePreview: React.FC = () => {
  const { product, taxRates } = useContext(SalesTabContext);
  const taxCategory = product?.saleDetails?.taxCategory;
  const taxRate = taxRates.find((rate) => rate.id === taxCategory);
  const taxValue = taxRate ? taxRate.value : 0;

  return (
    <>
      <PricePreviewHeader>
        {intl.formatMessage({
          id: 'sales.salesTab.pricePreview.footer',
          defaultMessage: 'Example customer purchase',
        })}
      </PricePreviewHeader>
      <PriceSegmentCard
        title={
          <BigBold>
            {intl.formatMessage({
              id: 'sales.salesTab.pricePreview',
              defaultMessage: 'Price preview',
            })}
          </BigBold>
        }
        style={{ marginBottom: '10px' }}
      >
        <GridTwoColumns>
          <div>
            <div>
              {intl.formatMessage({
                id: 'sales.salesTab.pricePreview.subtotal',
                defaultMessage: 'Subtotal',
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: 'sales.salesTab.pricePreview.tax',
                defaultMessage: 'Tax',
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: 'sales.salesTab.pricePreview.total',
                defaultMessage: 'Total',
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: 'sales.salesTab.pricePreview.revenue',
                defaultMessage: 'Revenue',
              })}
            </div>
          </div>
          <Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const price: ProductPrice = getFieldsValue();
              const isTaxIncluded = price.isTaxInclusive;
              const currency = price.currency;
              const tax =
                taxValue && price.unitAmount
                  ? getValueWithCurrency(
                      price.unitAmount * (taxValue / 100),
                      currency,
                    )
                  : getValueWithCurrency(0, currency);
              const unitAmount = getValueWithCurrency(
                price.unitAmount,
                currency,
              );
              const total = isTaxIncluded ? unitAmount : unitAmount.add(tax);
              const revenue = total.subtract(tax);
              return (
                <div>
                  <FlexCenter>{unitAmount.format()}</FlexCenter>
                  <FlexCenter>{tax.format()}</FlexCenter>
                  <FlexCenter>{total.format()}</FlexCenter>
                  <FlexCenterGray>{revenue.format()}</FlexCenterGray>
                </div>
              );
            }}
          </Item>
        </GridTwoColumns>
      </PriceSegmentCard>
    </>
  );
};

export default PricePreview;
