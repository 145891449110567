import React, { FC, useState, useCallback, useEffect } from 'react';
import { Input, Button, Space, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardForm } from './Board.sc';
import SelectWithSearch from '@components/SelectWithSearch';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { Option } from '@apps/weekplan/mainMenu/WeekPlan/common';
import ResourceLabel from '@apps/weekplan/mainMenu/WeekPlan/ResourceLabel';

type OnAddPayload = {
  title: string;
  description: string;
  resourceId?: string;
};

type NewCardFormProps = {
  onAdd(onAddPayload: OnAddPayload): void;
  onCancel(props: any): void;
  activeEcosystems: Ecosystem[];
  members: EcosystemMember[];
};

const NewCardForm: FC<NewCardFormProps> = ({ onAdd, onCancel, members }) => {
  const { getFieldValue, resetFields } = useFormContext();
  const [usersOptions, setUsersOptions] = useState<Option[]>(
    members.map((member) => ({
      key: member.id,
      value: member.id,
      label: <ResourceLabel resource={member} />,
    })),
  );
  const intl = useIntl();

  const ecosystem = getFieldValue('ecosystem');

  useEffect(() => {
    setUsersOptions(
      members.map((member) => ({
        key: member.id,
        value: member.id,
        label: <ResourceLabel resource={member} />,
      })),
    );
  }, [members]);

  const handleAdd = useCallback(() => {
    onAdd({
      title: getFieldValue('newTask.title'),
      description: getFieldValue('newTask.description'),
      resourceId: getFieldValue('newTask.resourceId'),
    });
  }, [getFieldValue, onAdd]);

  const handleCancel = (props: any) => {
    onCancel(props);
    resetFields(['newTask.title', 'newTask.description', 'newTask.resourceId']);
  };

  return (
    <CardForm
      className="animate__animated animate__slideInRight animate__faster"
      title={
        <Form.Item name="newTask.title" required={true} noStyle>
          <Input
            type="text"
            placeholder={intl.formatMessage({
              id: 'app.weekplan.card.title',
              defaultMessage: 'Title',
            })}
          />
        </Form.Item>
      }
      actions={[
        <Button type="primary" onClick={handleAdd}>
          <FormattedMessage
            id="app.weekplan.newCardForm.button.add"
            defaultMessage="Add"
          />
        </Button>,
        <Button onClick={handleCancel}>
          <FormattedMessage
            id="app.weekplan.newCardForm.button.cancel"
            defaultMessage="Cancel"
          />
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Form.Item name="newTask.resourceId" noStyle>
          <SelectWithSearch
            disabled={!ecosystem}
            options={usersOptions}
            width="100%"
            placeholder={intl.formatMessage({
              id: 'app.weekplan.card.resource',
              defaultMessage: 'Resource',
            })}
          />
        </Form.Item>
        <Form.Item name="newTask.description" required={true} noStyle>
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'app.weekplan.card.description',
              defaultMessage: 'Description',
            })}
          />
        </Form.Item>
      </Space>
    </CardForm>
  );
};

export default NewCardForm;
