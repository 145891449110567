import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import SalesAppConfig from '@apps/sales';
import { FormattedMessage } from 'react-intl';
import Tabs from '@components/Tabs';
import { patchProduct, Product } from '@apps/products/services';
import { isAppActivatedInEcosystem } from '@apps/utils';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import Details from '@apps/sales/widgets/SalesTab/Details';
import removeUndefined from '@core/helpers/removeUndefined';
import Pricing from '@apps/sales/widgets/SalesTab/Pricing';
import { getEcosystemTaxCategories } from '@apps/finance/services/taxCategories';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const { TabPane } = Tabs;

export const getEntireForm = (forms: any) => {
  return Object.values(forms).reduce((acc: any, formEl: any) => {
    acc = {
      ...acc,
      ...formEl.getFieldValue(),
    };
    return acc;
  }, {});
};

type GetSalesTabProps = {
  integratedAppId: string;
  fullEcosystem: Ecosystem;
  forms: any;
  id?: string;
  readOnly?: boolean;
  initialValues?: Partial<Product>;
  onCreateSubmit?: (values: any) => void;
  onSubmitSuccess?: () => void;
};

type GetSalesTab = (payload: GetSalesTabProps) => any;

const SalesTab: FC<GetSalesTabProps> = ({
  id,
  initialValues,
  integratedAppId,
  readOnly,
  onSubmitSuccess,
  forms,
  onCreateSubmit,
  fullEcosystem,
}) => {
  const [taxRates, setTaxRates] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fullEcosystem) {
      setLoading(true);
      getEcosystemTaxCategories(fullEcosystem.id)
        ?.then(setTaxRates)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fullEcosystem]);

  return (
    <Spin spinning={loading}>
      <SalesTabContext.Provider
        value={{
          taxRates,
          productId: id,
          product: initialValues,
          ecosystem: fullEcosystem,
          readOnly,
        }}
      >
        <AppEntityForm
          elId={id}
          initialValues={{ ...initialValues }}
          name="salesTab"
          appId={integratedAppId}
          readOnly={readOnly}
          onSubmit={(values) => {
            if (id) {
              patchProduct(id as string, {
                ...values,
                saleDetails: values.saleDetails
                  ? removeUndefined(values.saleDetails)
                  : {},
              }).then(() => {
                if (onSubmitSuccess) {
                  onSubmitSuccess();
                }
              });
            } else {
              const entireProduct = getEntireForm(forms);
              if (onCreateSubmit) {
                onCreateSubmit(entireProduct);
              }
            }
          }}
        >
          {() => {
            return (
              <>
                <Pricing />
                <Details />
              </>
            );
          }}
        </AppEntityForm>
      </SalesTabContext.Provider>
    </Spin>
  );
};

export const getSalesTab: GetSalesTab = (props) => {
  const { fullEcosystem } = props;
  const shouldRender = isAppActivatedInEcosystem(
    fullEcosystem,
    SalesAppConfig.todixId,
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <TabPane
      key={SalesAppConfig.todixId}
      tab={
        <>
          <SalesAppConfig.mainMenu.icon />
          <FormattedMessage
            id="sales.widget.salestab.title"
            defaultMessage="Sales"
          />
        </>
      }
    >
      <SalesTab {...props} />
    </TabPane>
  );
};
