import React from 'react';
import Tabs from '@components/Tabs';
import { isAppActivatedInEcosystem } from '@apps/utils';
import appConfig from '@apps/warehouse';
import { FormattedMessage } from 'react-intl';
import WarehouseForm from '@apps/warehouse/widgets/WarehouseForm';

const { TabPane } = Tabs;

type GetWarehouseTabTypePayload = {
  intl: any;
  fullEcosystem: Ecosystem;
  integratedAppId: string;
  productId?: string;
  readOnly?: boolean;
};

type GetArchivedDocumentsTabType = (payload: GetWarehouseTabTypePayload) => any;

const getWarehouseTab: GetArchivedDocumentsTabType = ({
  fullEcosystem,
  productId,
  integratedAppId,
  readOnly,
}) => {
  const shouldRender = isAppActivatedInEcosystem(
    fullEcosystem,
    appConfig.todixId,
  );
  if (!shouldRender) {
    return null;
  }

  return (
    <TabPane
      key={appConfig.todixId}
      tab={
        <>
          <appConfig.mainMenu.icon />
          <FormattedMessage id="app.warehouse" defaultMessage="Warehouse" />
        </>
      }
    >
      <WarehouseForm
        ecosystemId={fullEcosystem.id}
        productId={productId}
        integratedAppId={integratedAppId}
        readOnly={readOnly}
      />
    </TabPane>
  );
};

export default getWarehouseTab;
