import React, {
  FC,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Row, Col, Input, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import SelectedEcosystem from '@components/SelectedEcosystem';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig from '@apps/weekplan';
import { Lane, WeekPlanEntry } from '@apps/weekplan/services/namespace';
import addWeekPlan from '@apps/weekplan/services/addWeekPlan';
import getWeekPlanById from '@apps/weekplan/services/getWeekPlanById';
import { StyledBoard, Section, WeekPlanContainer } from './Board.sc';
import Card from './Card';
import NewCardForm from './NewCardForm';
import AddCardLink from './AddCardLink';
import updateWeekPlan from '@apps/weekplan/services/updateWeekPlan';
import { getTasksByEcosystemId, Task } from '@apps/tasks/services';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/weekplan';
import { getMembers } from '@services/ecosystems';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

type WeekPlanType = {
  lanes: Lane[];
  name: string;
};

type EventBus = {
  publish(payload: any): void;
};

const WeekPlan: FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [members, setMembers] = useState<EcosystemMember[]>([]);
  const [form] = Form.useForm();
  const eventBus = useRef<EventBus | null>(null);
  const activeEcosystems = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  );
  const firstActiveEcosystemId = useFirstActiveEcosystemId(appConfig.todixId);

  const setEventBus = (handle: any) => {
    eventBus.current = handle;
  };

  const lanes = useMemo(
    () => ({
      lanes: [
        {
          id: 'monday',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.monday',
            defaultMessage: 'Monday',
          }),
          cards: [],
          disallowAddingCard: true,
        },
        {
          id: 'tuesday',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.tuesday',
            defaultMessage: 'Tuesday',
          }),
          cards: [],
          disallowAddingCard: true,
        },
        {
          id: 'wednesday',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.wednesday',
            defaultMessage: 'Wednesday',
          }),
          cards: [],
          disallowAddingCard: true,
        },
        {
          id: 'thursday',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.thursday',
            defaultMessage: 'Thursday',
          }),
          cards: [],
          disallowAddingCard: true,
        },
        {
          id: 'friday',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.friday',
            defaultMessage: 'Friday',
          }),
          cards: [],
          disallowAddingCard: true,
        },
        {
          id: 'tasks',
          title: intl.formatMessage({
            id: 'app.weekplan.lane.tasks',
            defaultMessage: 'Tasks',
          }),
          cards: [],
          style: {
            gridColumnStart: 1,
            gridColumnEnd: 6,
          },
        },
      ],
    }),
    [intl],
  );

  const fetchMembersForEcosystem = useCallback((ecosystemId: string) => {
    setIsLoading(true);
    getMembers(ecosystemId)
      ?.then(setMembers)
      ?.finally(() => {
        setIsLoading(false);
      });
  }, []);

  const fetchTasksForEcosystemId = useCallback(
    (ecosystemId: string, weekPlan: WeekPlanEntry) => {
      const { lanes } = weekPlan;
      setIsLoading(true);
      getTasksByEcosystemId(ecosystemId)
        .then((tasks) => {
          if (lanes) {
            const cardsIds = lanes
              .map((lane) => lane.cards)
              .flat()
              .map((card) => card.id);

            setTasks(
              tasks.filter((task) =>
                task.id ? !cardsIds.includes(task.id) : true,
              ),
            );
          } else {
            setTasks(tasks);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    tasks.forEach((task) => {
      const resourceId = task.assignees ? task.assignees[0].id : null;
      eventBus?.current?.publish({
        type: 'ADD_CARD',
        laneId: 'tasks',
        card: {
          id: task.id,
          title: task.title || '',
          resourceId,
          description: task.description || '',
        },
      });
    });
  }, [tasks]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getWeekPlanById(id)
        .then((weekPlan) => {
          form.setFields([
            {
              name: 'name',
              value: weekPlan.name,
            },
            {
              name: 'ecosystem',
              value: weekPlan.ecosystem,
            },
            {
              name: 'lanes',
              value: weekPlan.lanes,
            },
          ]);

          fetchTasksForEcosystemId(weekPlan.ecosystem, weekPlan);
          fetchMembersForEcosystem(weekPlan.ecosystem);

          eventBus?.current?.publish({
            type: 'UPDATE_LANES',
            lanes: weekPlan.lanes,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [eventBus, fetchMembersForEcosystem, fetchTasksForEcosystemId, form, id]);

  const handleWeekPlanChange = useCallback(
    (newWeekPlan: WeekPlanType) => {
      form.setFields([
        {
          name: 'lanes',
          value: newWeekPlan.lanes,
        },
      ]);
    },
    [form],
  );

  const handleOnFinish = useCallback(
    (values) => {
      setIsLoading(true);
      if (id) {
        updateWeekPlan(id, values).finally(() => {
          setIsLoading(false);
          history.push(`/app/${appConfig.todixId}`);
        });
      } else {
        addWeekPlan(values).finally(() => {
          setIsLoading(false);
          history.push(`/app/${appConfig.todixId}`);
        });
      }
    },
    [history, id],
  );

  const StyleBoardComponents = {
    Card: (props: any) => (
      <Card {...props} activeEcosystems={activeEcosystems} members={members} />
    ),
    NewCardForm: (props: any) => (
      <NewCardForm
        {...props}
        activeEcosystems={activeEcosystems}
        members={members}
      />
    ),
    AddCardLink,
    Section,
  };

  return (
    <WeekPlanContainer>
      <Spin spinning={isLoading}>
        <AppEntityForm
          providedForm={form}
          initialValues={{
            ...lanes,
            ecosystem: firstActiveEcosystemId,
          }}
          appId={AppConfig.todixId}
          onSubmit={handleOnFinish}
          readOnly={false}
        >
          {() => {
            return (
              <>
                <Row>
                  <Col span={12}>
                    <SelectedEcosystem
                      appId={AppConfig.todixId}
                      onChange={(id: string) => {
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          ...lanes,
                        });
                        fetchTasksForEcosystemId(
                          id,
                          form.getFieldValue('lanes'),
                        );
                        fetchMembersForEcosystem(id);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'app.weekplan.form.name.label',
                        defaultMessage: 'Name',
                      })}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage
                              id="app.weekplan.form.name.required"
                              defaultMessage="Missing name"
                            />
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'app.weekplan.form.name.label',
                          defaultMessage: 'Name',
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="lanes" noStyle>
                      <StyledBoard
                        data={lanes}
                        components={StyleBoardComponents}
                        onDataChange={handleWeekPlanChange}
                        draggable={false}
                        editable
                        eventBusHandle={setEventBus}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </AppEntityForm>
      </Spin>
    </WeekPlanContainer>
  );
};

export default WeekPlan;
