import * as React from 'react';
import { Form, Avatar } from 'antd';
import { Select } from '@components/FormFields';
import { StateProps } from '@apps/contacts/mainMenu/components/DetailContactForm/SelectedEcosystem';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

export const SelectedEcosystem: React.FC<StateProps> = ({ ecosystems }) => {
  const { readOnly } = useFormContext();
  return (
    <Form.Item
      name="ecosystem"
      label={
        <FormattedMessage
          id="detailcontactform.selectedecosystem.ecosystem.label"
          defaultMessage="Ecosystem"
        />
      }
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="detailcontactform.selectedecosystem.ecosystem.error"
              defaultMessage="Missing ecosystem"
            />
          ),
        },
      ]}
    >
      <Select
        name="ecosystem"
        placeholder={
          <FormattedMessage
            id="detailcontactform.selectedecosystem.ecosystem.placeholder"
            defaultMessage="Ecosystem"
          />
        }
        aria-required="true"
        disabled={readOnly}
      >
        {ecosystems.map((ecosystem) => (
          <Option key={ecosystem.id} value={ecosystem.id}>
            <Avatar src={ecosystem.avatarUrl} size="small" /> {ecosystem.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
