import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { getLicences } from '@core/store/licences';
import { getStripeProducts } from '@core/store/stripeProducts/selectors';

const useFirstActiveEcosystemId = (todixId: string) => {
  const stripeApps = useSelector(getStripeProducts);
  const stripeProduct =
    stripeApps.find((app) => app.todixId === todixId) || null;
  const activeEcosystems = useSelector(getActiveEcosystems);
  const activeEcosystemIds = activeEcosystems?.map((el) => el.id);
  const licences = useSelector(getLicences);
  const filteredLicences = Object.values(licences.data).filter(
    (el) => el.appId === stripeProduct?.id,
  );
  const activeAppEcosystemIds = filteredLicences
    .map((el) => el.ecosystem)
    .filter((el) => activeEcosystemIds.includes(el));
  return activeAppEcosystemIds[0];
};

export default useFirstActiveEcosystemId;
