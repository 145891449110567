import * as React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { FullScreenHandle } from 'react-full-screen';
import {
  Logo,
  Header,
  LogoWrapper,
} from '@core/layout/components/DashboardLayout/DashboardLayout.sc';
import { ActionsBar, LeftMenu } from '@core/layout/components';
import { DispatchProps, StateProps } from '@core/layout';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const MainLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

const ContentLayout = styled(Layout)`
  position: relative;
  padding: 24px;
  padding-top: 12px;
  height: calc(100vh - 56px);
  width: 100% !important;
  overflow: auto;
  top: 56px;
  bottom: 0;
  left: 0;
`;

export type OwnProps = {
  fullScreenHandler: FullScreenHandle;
  children?: React.ReactNode;
};

type Props = StateProps & DispatchProps;

export const DashboardLayout: React.FC<Props & OwnProps> = ({
  fetchApplications,
  children,
  fullScreenHandler,
}) => {
  React.useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);
  return (
    <MainLayout>
      <Header>
        <Link to="/">
          <LogoWrapper>
            <Logo className="todix-logo" />
            <FormattedMessage
              id="dashboardlayout.logo"
              defaultMessage="Todix"
            />
          </LogoWrapper>
        </Link>
        <ActionsBar fullScreenHandler={fullScreenHandler} />
      </Header>
      <LeftMenu />
      <ContentLayout>{children}</ContentLayout>
    </MainLayout>
  );
};
