import React, { FC, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Tabs from '@components/Tabs';
import { Row, Col } from 'antd';
import { AgGridReactProps } from 'ag-grid-react';
import {
  CloseSquareOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { Button } from '@todix/ui-components';
import { ColumnState } from 'ag-grid-community';

import 'ag-grid-community/dist/styles/agGridAlpineFont.css';
import SpinContainer from '@components/SpinContainer';
import QuickFilter from './QuickFilter';
import SideView from './SideView';
import { RootStore } from '@core/store';
import {
  getActiveEcosystems,
  EcosystemState,
} from '@components/EcosystemIndicator/store';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import { GetRows } from '../List';
import ListWithOrderSave from '../List/ListWithOrderSave';
import { ExtraOptions } from './ActionRenderer';
import { FormattedMessage } from 'react-intl';
import { StackedItemsLoadedEventPayload } from '@components/AppView/Stacked';
import { Document } from '@apps/documents/service';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import TabsLeftAligned from '@components/TabsLeftAligned';
import StackedView from '@components/AppView/StackedView';

const { TabPane } = Tabs;

type InTabHeaderProps = {
  onFilterSelect: (option: string) => void;
  disableClearSort: boolean;
  onClickClearSort: () => void;
  headerColRef?: any;
  hideQuickFilter?: boolean;
  setStackedSort: (arg: StackedSortType) => void;
  stackedSort: StackedSortType;
  currentTab: AppViewTab;
};

const InTabHeader: React.FC<InTabHeaderProps> = ({
  onFilterSelect,
  disableClearSort,
  onClickClearSort,
  headerColRef,
  stackedSort,
  setStackedSort,
  currentTab,
  hideQuickFilter = false,
}) => (
  <Row align="middle" justify="space-between" style={{ margin: '0 0 8px' }}>
    <Col>
      {!hideQuickFilter && <QuickFilter onSelect={onFilterSelect as any} />}
    </Col>
    <Col>
      <Row gutter={8}>
        {headerColRef && <Col ref={headerColRef} />}
        {currentTab === 'stacked' && (
          <Col>
            <Button
              type="dashed"
              size="small"
              icon={
                stackedSort === 'asc' ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              onClick={() =>
                stackedSort === 'asc'
                  ? setStackedSort('desc')
                  : setStackedSort('asc')
              }
            >
              <span>
                {stackedSort === 'asc' ? (
                  <FormattedMessage
                    id="appview.stackedsortasc"
                    defaultMessage="Sort: Oldest to newest"
                  />
                ) : (
                  <FormattedMessage
                    id="appview.stackedsortdesc"
                    defaultMessage="Sort: Newest to oldest"
                  />
                )}
              </span>
            </Button>
          </Col>
        )}
        {currentTab !== 'stacked' && (
          <Col>
            <Button
              type="dashed"
              size="small"
              icon={<CloseSquareOutlined />}
              disabled={disableClearSort}
              onClick={onClickClearSort}
            >
              <span>
                <FormattedMessage
                  id="appview.clearsort"
                  defaultMessage="Clear sort"
                />
              </span>
            </Button>
          </Col>
        )}
      </Row>
    </Col>
  </Row>
);

export type AppViewEvent = 'selectItem';

export type SelectItemPayload = {
  id: string;
  extraAction?(): void;
};

export type SideContentProps = {
  id: string;
  hideActionsInSideContent?: boolean;
};

export type ActionOption = {
  action: string;
  label: string;
};

export type AppViewProps = AgGridReactProps & {
  breadcrumbItems: string[][];
  columns: any;
  options?: ActionOption[];
  activeEcosystems: EcosystemState;
  getRows: GetRows;
  sideContent: React.ComponentType<SideContentProps>;
  path: string;
  onCellClickPath: string;
  tabs: AppViewTab[];
  onRemoveItem?(id: string): Promise<void> | undefined;
  onResourceChangeEventName?: string;
  onResourceSaveEventName?: string;
  getExtraOptions?: (data: any, refreshList: () => void) => ExtraOptions;
  hideActionsInSideContent?: boolean;
  hideQuickFilter?: boolean;
  docsToProcessing?: Partial<Document>[];
  className?: string;
  actionListCells?: string[];
};

type AppViewTab = 'list' | 'stacked' | 'network' | 'cards';

export const SORTING = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

type SortingKeys = keyof typeof SORTING;

export type StackedSortType = typeof SORTING[SortingKeys];

const defaultActionListCells = ['actionRenderer', 'relationSelectRenderer'];

const AppView: FC<AppViewProps> = (props) => {
  const actionListCells = props.actionListCells
    ? [...new Set([...props.actionListCells, ...defaultActionListCells])]
    : defaultActionListCells;
  const [quickFilterText, setQuickFilterText] = useState('');
  const [currentTab] = useState<AppViewTab>(
    props.tabs ? props.tabs[0] : 'list',
  );
  const [rowsCount, setRowsCount] = useState(0);
  const [stackedSort, setStackedSort] = useState<StackedSortType>(SORTING.ASC);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const listRef = useRef<any>(null);
  const headerColRef = useRef<any>(null);

  useCustomEventListener<AppViewEvent, SelectItemPayload>('selectItem', (e) => {
    if (e?.id) {
      setSelectedRow(e.id);
    }
    if (e?.extraAction) {
      e.extraAction();
    }
  });

  useCustomEventListener<'stackedItemsLoaded', StackedItemsLoadedEventPayload>(
    'stackedItemsLoaded',
    (e) => {
      if (e) {
        setRowsCount(e.items);
      }
    },
  );

  useEffect(() => {
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount,
        currentTab,
        breadcrumbItems: props.breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [currentTab, props.breadcrumbItems, rowsCount]);

  const clearSort = () => {
    listRef.current?.columnApi.applyColumnState({
      defaultState: { sort: null },
    });
  };

  const SideContent = props.sideContent;
  return (
    <TabsLeftAligned
      // first available
      defaultActiveKey={props.tabs[0]}
      className={props.className}
      tabBarExtraContent={{
        right: (
          <InTabHeader
            onFilterSelect={setQuickFilterText}
            disableClearSort={
              !listRef.current?.columnApi
                .getColumnState()
                .find((col: ColumnState) => col.sort)
            }
            onClickClearSort={clearSort}
            headerColRef={headerColRef}
            stackedSort={stackedSort}
            setStackedSort={setStackedSort}
            currentTab={currentTab}
            hideQuickFilter={props.hideQuickFilter}
          />
        ),
      }}
    >
      <TabPane
        tab={
          <FormattedMessage
            id="appview.listview.tab"
            defaultMessage="List view"
          />
        }
        key="list"
        disabled={!props.tabs.includes('list')}
      >
        <Row style={{ height: 'calc(100vh - 160px)' }} gutter={24}>
          <Col span={selectedRow ? 14 : 24}>
            <ListWithOrderSave
              gridRef={listRef}
              options={props.options}
              columns={props.columns}
              getRows={props.getRows}
              quickFilterText={quickFilterText}
              activeEcosystems={props.activeEcosystems}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              setRowsCount={setRowsCount}
              path={props.path}
              onCellClickPath={props.onCellClickPath}
              onRemoveItem={props.onRemoveItem}
              getExtraOptions={props.getExtraOptions}
              actionListCells={actionListCells}
            />
          </Col>
          {!!selectedRow && (
            <SideView>
              <SideContent
                id={selectedRow}
                hideActionsInSideContent={props.hideActionsInSideContent}
              />
            </SideView>
          )}
        </Row>
      </TabPane>
      <TabPane
        tab={
          <FormattedMessage
            id="appview.stackedview.tab"
            defaultMessage="Stacked view"
          />
        }
        key="stacked"
        disabled={!props.tabs.includes('stacked')}
      >
        <StackedView
          SideContent={SideContent}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          stackedSort={stackedSort}
          setStackedSort={setStackedSort}
          headerColRef={headerColRef}
          docsToProcessing={props.docsToProcessing}
          onResourceChangeEventName={props.onResourceChangeEventName}
          onResourceSaveEventName={props.onResourceSaveEventName}
          hideActionsInSideContent={props.hideActionsInSideContent}
        />
      </TabPane>
      <TabPane
        tab={
          <FormattedMessage
            id="appview.cardsview.tab"
            defaultMessage="Cards view"
          />
        }
        key="3"
        disabled={!props.tabs.includes('cards')}
      >
        <SpinContainer />
      </TabPane>
      <TabPane
        tab={
          <FormattedMessage
            id="appview.networkview.tab"
            defaultMessage="Network view"
          />
        }
        key="4"
        disabled={!props.tabs.includes('network')}
      >
        <SpinContainer />
      </TabPane>
    </TabsLeftAligned>
  );
};

const mapState = (state: RootStore) => ({
  activeEcosystems: getActiveEcosystems(state),
});

export default connect(mapState)(AppView);
