import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';

export default {
  todixId: 'sales',
  link: '/app/sales',
  name: 'sales',
  entityType: 'sale',
  collectionName: 'sales',
  localizationPath: 'sales/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.sales.createNewLink.new.sales',
    },
  ],
  widgets: [
    {
      name: 'StatisticsWidget',
      path: 'sales/widgets/StatisticsWidget',
    },
    {
      name: 'DashboardWidget',
      path: 'sales/widgets/DashboardWidget',
    },
  ],
  listCellRenderers: [
    {
      name: 'customerCellRenderer',
      path: 'sales/widgets/CustomerCellRenderer',
    },
    {
      name: 'stepsStatusCellRenderer',
      path: 'sales/widgets/StepsStatusCellRenderer',
    },
    {
      name: 'sumCellRenderer',
      path: 'sales/widgets/SumCellRenderer',
    },
    {
      name: 'salePaymentStatusCellRenderer',
      path: 'sales/widgets/SalePaymentStatusCellRenderer',
    },
    {
      name: 'saleShipmentStatusCellRenderer',
      path: 'sales/widgets/SaleShipmentStatusCellRenderer',
    },
  ],
};
