import { RootStore } from '@core/store';
import { EcosystemState } from '@components/EcosystemIndicator/store/reducers';
import { getLicences } from '@core/store/licences';
import { getEcosystemRelations } from '@core/store/ecosystemRelations';
import { getStripeProducts } from '@core/store/stripeProducts/selectors';

export const getOwnerEcosystem =
  (uid: string) =>
  (state: RootStore): Ecosystem[] =>
    state.ecosystems.filter((eco) => eco.ownerUid === uid);

export const getEcosystems = (state: RootStore): EcosystemState =>
  state.ecosystems;

export const getPrivateEcosystem = (state: RootStore): Ecosystem => {
  return getEcosystems(state).find(
    (eco) => eco.type === 'private',
  ) as Ecosystem;
};

export const getActiveEcosystems = (state: RootStore): EcosystemState => {
  const activeEcosystems = state.ecosystems.filter((eco) => eco.isActive);
  const ecosystemRelations = state.ecosystemRelations.data;
  if (ecosystemRelations.length < 1) {
    return [];
  }

  return activeEcosystems.filter((ecosystem) => {
    const relation = ecosystemRelations.find(
      (relation) =>
        relation.ecosystem === ecosystem.id &&
        relation.isActive &&
        !relation.disabled,
    );
    return !!relation;
  });
};

export const getActiveEcosystemsForApp = (
  state: RootStore,
  todixId: string,
): EcosystemState => {
  const licenceState = getLicences(state);
  const licences = Object.values(licenceState.data);
  const activeEcosystems = getActiveEcosystems(state);
  const ecosystemRelations = getEcosystemRelations(state);
  const stripeApps = getStripeProducts(state);
  const appId = stripeApps.find((app) => app.todixId === todixId)?.id || '';
  const filteredActiveEcosystems = activeEcosystems.filter((ecosystem) => {
    const relation = ecosystemRelations.find(
      (relation) =>
        relation.ecosystem === ecosystem.id &&
        relation.isActive &&
        !relation.disabled,
    );
    return !!relation;
  });
  let activeEcosystemsForApp: EcosystemState = [];
  filteredActiveEcosystems.forEach((ecosystem) => {
    if (
      licences.find(
        (lic) => lic.ecosystem === ecosystem.id && lic.appId === appId,
      )
    ) {
      activeEcosystemsForApp = [...activeEcosystemsForApp, ecosystem];
    }
  });

  return activeEcosystemsForApp;
};

export const getEcosystemById =
  (id: string) =>
  (state: RootStore): Ecosystem | undefined =>
    state.ecosystems.find((eco: Ecosystem) => eco.id === id);
