import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';
import { get as apiGet } from '@services/api';
import {
  Consultant,
  CONSULTANTS_COLLECTION_NAME,
  ConsultantWithStats,
} from '@apps/directSales/mainMenu/Consultants/services/namespace';
import get from 'lodash.get';
import { getFilteredData } from '@services/filtering';
import set from 'lodash.set';
import { GetConsultants } from '@apps/directSales/mainMenu/Consultants/services/index';
import getLatestCommissionPeriodStatsForConsultant from '@apps/directSales/services/commisions/getLatestCommissionPeriodStatsForConsultant';

// @ts-ignore
const getConsultantsWithStats: GetConsultants = (p) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 50,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(CONSULTANTS_COLLECTION_NAME)?.then((data: any[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (valueA < valueB) {
            return desc ? 1 : -1;
          } else {
            return desc ? -1 : 1;
          }
        })
      : filtered;
    const choosenFields: Partial<Consultant>[] = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow as Partial<Consultant>;
    });

    //fetch latest stats
    const statsPromises = choosenFields
      .map((consultant) => {
        if (consultant.ecosystem) {
          return getLatestCommissionPeriodStatsForConsultant(
            consultant.ecosystem,
            consultant.id || '',
          )?.then((entries) => {
            if (entries) {
              return entries[0];
            }
            return null;
          });
        }
        return null;
      })
      .filter((entry) => entry !== null);

    return Promise.all(statsPromises).then((stats) => {
      const consultants: ConsultantWithStats[] = choosenFields.map(
        (consultant) => {
          const consultantStats = stats.find(
            (stat) => stat?.consultantId === consultant.id,
          );
          return {
            ...(consultant as Consultant),
            stats: consultantStats || null,
          };
        },
      );

      const results = consultants.length;
      const page = consultants.slice(
        params.offset,
        params.offset + params.limit,
      );

      return {
        results: page,
        info: {
          results,
        },
      };
    });
  });
};

export default getConsultantsWithStats;
